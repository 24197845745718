const profilesActions = {
    //PROFILES
    LOAD_PROFILES_START: "LOAD_PROFILES_START",
    LOAD_PROFILES_SUCCESS: "LOAD_PROFILES_SUCCESS",
    LOAD_PROFILES_FAILED: "LOAD_PROFILES_FAILED",

    //CONTRACTOR_PROFILES
    LOAD_CONTRACTOR_PROFILES_START: "LOAD_CONTRACTOR_PROFILES_START",
    LOAD_CONTRACTOR_PROFILES_SUCCESS: "LOAD_CONTRACTOR_PROFILES_SUCCESS",
    LOAD_CONTRACTOR_PROFILES_FAILED: "LOAD_CONTRACTOR_PROFILES_FAILED",

    //CREATE_PROFILE
    CREATE_PROFILE_START: "CREATE_PROFILE_START",
    CREATE_PROFILE_SUCCESS: "CREATE_PROFILE_SUCCESS",
    CREATE_PROFILE_FAILED: "CREATE_PROFILE_FAILED",

    //EDIT_PROFILE
    EDIT_PROFILE_START: "EDIT_PROFILE_START",
    EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
    EDIT_PROFILE_FAILED: "EDIT_PROFILE_FAILED",

    //PROFILE_DETAILS
    LOAD_PROFILE_DETAILS_START: "LOAD_PROFILE_DETAILS_START",
    LOAD_PROFILE_DETAILS_SUCCESS: "LOAD_PROFILE_DETAILS_SUCCESS",
    LOAD_PROFILE_DETAILS_FAILED: "LOAD_PROFILE_DETAILS_FAILED",

    //CLEAR_FLAGS
    CLEAR_FLAGS: "CLEAR_FLAGS",

    //CLEAR_SELECTED_PROJECT
    CLEAR_SELECTED_PROFILE_START: "CLEAR_SELECTED_PROFILE_START",
    CLEAR_SELECTED_PROFILE_SUCCESS: "CLEAR_SELECTED_PROFILE_SUCCESS",

    // Profile Actions
    createLoadProfilesActionStart: (profileIds) => {
      return {
        type: profilesActions.LOAD_PROFILES_START,
        payload: profileIds,
      };
    },
    createLoadProfilesActionSuccess: (contractors) => {
      return {
        type: profilesActions.LOAD_PROFILES_SUCCESS,
        payload: contractors,
      };
    },
    createLoadProfilesActionFailed: (error) => {
      return {
        type: profilesActions.LOAD_PROFILES_FAILED,
        payload: error
      };
    },
    createLoadContractorProfilesActionStart: (profileIds) => {
      return {
        type: profilesActions.LOAD_CONTRACTOR_PROFILES_START,
        payload: profileIds,
      };
    },
    createLoadContractorProfilesActionSuccess: (contractors) => {
      return {
        type: profilesActions.LOAD_CONTRACTOR_PROFILES_SUCCESS,
        payload: contractors,
      };
    },
    createLoadContractorProfilesActionFailed: (error) => {
      return {
        type: profilesActions.LOAD_CONTRACTOR_PROFILES_FAILED,
        payload: error
      };
    },
    createCreateProfileActionStart: (profile) => {
      return {
        type: profilesActions.CREATE_PROFILE_START,
        payload: profile,
      };
    },
    createCreateProfileActionSuccess: (success) => {
      return {
        type: profilesActions.CREATE_PROFILE_SUCCESS,
        payload: success,
      };
    },
    createCreateProfileActionFailed: (error) => {
      return {
        type: profilesActions.CREATE_PROFILE_FAILED,
        payload: error,
      };
    },
    createEditProfileActionStart: (profile) => {
      return {
        type: profilesActions.EDIT_PROFILE_START,
        payload: profile,
      };
    },
    createEditProfileActionSuccess: (success) => {
      return {
        type: profilesActions.EDIT_PROFILE_SUCCESS,
        payload: success,
      };
    },
    createEditProfileActionFailed: (error) => {
      return {
        type: profilesActions.EDIT_PROFILE_FAILED,
        payload: error,
      };
    },
    clearFlags: (payload) => {
      return {
        type: profilesActions.CLEAR_FLAGS,
        payload: payload,
      };
    },
    clearSelectedProfileStart:() => {
      return {
        type: profilesActions.CLEAR_SELECTED_PROFILE_START,
      };
    },
    clearSelectedProfileSuccess:(payload) => {
      return {
        type: profilesActions.CLEAR_SELECTED_PROFILE_SUCCESS,
        payload: payload,
      };
    },
    createLoadProfileDetailsActionStart: (profileId) => {
      return {
        type: profilesActions.LOAD_PROFILE_DETAILS_START,
        payload: profileId,
      };
    },
    createLoadProfileDetailsActionSuccess: (profiles) => {
      return {
        type: profilesActions.LOAD_PROFILE_DETAILS_SUCCESS,
        payload: profiles,
      };
    },
    createLoadProfileDetailsActionFailed: (error) => {
      return {
        type: profilesActions.LOAD_PROFILE_DETAILS_FAILED,
        payload: error,
      };
    },
  };
  
  export default profilesActions;
  



  
