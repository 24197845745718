const changeOrdersActions = {
  //Load change orders
  LOAD_CHANGE_ORDERS_START: "LOAD_CHANGE_ORDERS_START",
  LOAD_CHANGE_ORDERS_SUCCESS: "LOAD_CHANGE_ORDERS_SUCCESS",
  LOAD_CHANGE_ORDERS_FAILED: "LOAD_CHANGE_ORDERS_FAILED",

  //Create change orders
  CREATE_CHANGE_ORDER_START: "CREATE_CHANGE_ORDER_START",
  CREATE_CHANGE_ORDER_SUCCESS: "CREATE_CHANGE_ORDER_SUCCESS",
  CREATE_CHANGE_ORDER_FAILED: "CREATE_CHANGE_ORDER_FAILED",

  //Update change orders
  UPDATE_CHANGE_ORDER_START: "UPDATE_CHANGE_ORDER_START",
  UPDATE_CHANGE_ORDER_SUCCESS: "UPDATE_CHANGE_ORDER_SUCCESS",
  UPDATE_CHANGE_ORDER_FAILED: "UPDATE_CHANGE_ORDER_FAILED",

  //Delete change orders
  DELETE_CHANGE_ORDER_START: "DELETE_CHANGE_ORDER_START",
  DELETE_CHANGE_ORDER_SUCCESS: "DELETE_CHANGE_ORDER_SUCCESS",
  DELETE_CHANGE_ORDER_FAILED: "DELETE_CHANGE_ORDER_FAILED",

  //Modals and other actions
  TOGGLE_CHANGE_ORDER_MODAL: "TOGGLE_CHANGE_ORDER_MODAL",

  // Change orders action cretors
  createLoadChangeOrdersStartAction: (projectId) => {
    return {
      type: changeOrdersActions.LOAD_CHANGE_ORDERS_START,
      payload: { projectId },
    };
  },
  createLoadChangeOrdersSuccessAction: (changeOrders) => {
    return {
      type: changeOrdersActions.LOAD_CHANGE_ORDERS_SUCCESS,
      payload: changeOrders,
    };
  },
  createLoadChangeOrdersFailedAction: (changeOrdersError) => {
    return {
      type: changeOrdersActions.LOAD_CHANGE_ORDERS_FAILED,
      payload: changeOrdersError,
    };
  },

  createCreateChangeOrderStartAction: (changeOrder) => {
    return {
      type: changeOrdersActions.CREATE_CHANGE_ORDER_START,
      payload: changeOrder,
    };
  },
  createCreateChangeOrderSuccessAction: (changeOrderSuccess) => {
    return {
      type: changeOrdersActions.CREATE_CHANGE_ORDER_SUCCESS,
      payload: changeOrderSuccess,
    };
  },
  createCreateChangeOrderFailedAction: (changeOrderError) => {
    return {
      type: changeOrdersActions.CREATE_CHANGE_ORDER_FAILED,
      payload: changeOrderError,
    };
  },
  // update action creators
  createUpdateChangeOrderStartAction: (changeOrder) => {
    return {
      type: changeOrdersActions.UPDATE_CHANGE_ORDER_START,
      payload: changeOrder,
    };
  },
  createUpdateChangeOrderSuccessAction: (changeOrderSuccess) => {
    return {
      type: changeOrdersActions.UPDATE_CHANGE_ORDER_SUCCESS,
      payload: changeOrderSuccess,
    };
  },
  createUpdateChangeOrderFailedAction: (changeOrderError) => {
    return {
      type: changeOrdersActions.UPDATE_CHANGE_ORDER_FAILED,
      payload: changeOrderError,
    };
  },
  // delete action creators
  createDeleteChangeOrderStartAction: (changeOrder) => {
    return {
      type: changeOrdersActions.DELETE_CHANGE_ORDER_START,
      payload: changeOrder,
    };
  },
  createDeleteChangeOrderSuccessAction: (changeOrderSuccess) => {
    return {
      type: changeOrdersActions.DELETE_CHANGE_ORDER_SUCCESS,
      payload: changeOrderSuccess,
    };
  },
  createDeleteChangeOrderFailedAction: (changeOrderError) => {
    return {
      type: changeOrdersActions.DELETE_CHANGE_ORDER_FAILED,
      payload: changeOrderError,
    };
  },

  // Modal and other action creators
  toggleModal: (data = null) => ({
    type: changeOrdersActions.TOGGLE_CHANGE_ORDER_MODAL,
    payload: { data },
  }),
};

export default changeOrdersActions;
