import moment from 'moment';
import { PAYMENT_STATUSES, defaultOwnerEmailAddress } from './globalEnumsAndConsts';
import { getCurrentUserEmail } from '@iso/lib/firebase/firebase.authentication.util';
import { isLoggedInUserAContractor } from "@iso/lib/remod-helpers/localStorage";
import { cloneDeep } from "lodash";
import { COST_TYPES, DEFAULT_CATEGORY } from '@iso/lib/remod-helpers/globalEnumsAndConsts';

const FIXED_SAGA_ARRAY_FOR_QUERY = 10;

export const createNewProject = (userId, projectName, projectDescription, status, purpose, zip) => {
    const ownerId = isLoggedInUserAContractor() ? null : userId ?? defaultOwnerEmailAddress;
    return {
        name: projectName,
        description: projectDescription,
        address: zip,
        ownerId: ownerId,
        ownerName: null,
        projectPurpose: purpose,
        status: status,
        createdAt: moment(Date.now()).toString(),
        createdBy: userId ?? defaultOwnerEmailAddress,
        lastModifiedAt: moment(Date.now()).toString(),
        lastModifiedBy: userId ?? defaultOwnerEmailAddress,
    };
};

export const createNewContractorFromEmail = (email, projectId) => {
    return {
        name: 'Name ' + email,
        title: 'Title ' + email,
        email: email,
        projectId: projectId,
        createdAt: moment(Date.now()).toString(),
        lastModifiedAt: moment(Date.now()).toString(),
    }
}

export const createNewPayment = (projectId, userId) => {
    return {
        name: null,
        description: null,
        paymentSource: null,
        projectId: projectId,
        projectItemId: null,
        status: PAYMENT_STATUSES.pending,
        value: null,
        paidAt: moment(Date.now()).toString(),
        createdAt: moment(Date.now()).toString(),
        createdBy: userId,
        lastModifiedAt: moment(Date.now()).toString(),
        lastModifiedBy: userId,
    };
};

export const createEmptyPayment = (projectId, userId, projectItemId, name) => {
    const emptyPayment = createNewPayment(projectId, userId);
    emptyPayment.value = 0;
    emptyPayment.projectItemId = projectItemId;
    emptyPayment.name = name;
    return emptyPayment;
}

export const createNewChangeOrder = (projectId, userId, contractorId, parentProjectItemId) => {
    return {
        name: '',
        cost: 0,
        inScope: true,
        changeOrder: true,
        changeOrderApproved: false,
        description: null,
        unanswered: false,
        projectId: projectId,
        parentProjectItemId: parentProjectItemId,
        contractorId: contractorId,
        contractorProfileId: null,
        createdBy: userId ?? defaultOwnerEmailAddress,
        lastModifiedBy: userId ?? defaultOwnerEmailAddress,
        createdAt: moment(Date.now()).toString(),
        lastModifiedAt: moment(Date.now()).toString(),
    };
}

export const clonePayment = (inputPayment) => {
    const cloneObject = {
        name: inputPayment.name,
        value: inputPayment.value,
        projectId: inputPayment.projectId,
        paidAt: inputPayment.paidAt ?? moment(Date.now()).toString(),
        paymentSource: inputPayment.paymentSource ?? '',
        description: inputPayment.description ?? '',
        status: inputPayment.status ?? PAYMENT_STATUSES.PENDING,
        contractorId: inputPayment.contractorId ?? '',
        files: inputPayment.files ?? [],
        createdBy: inputPayment.createdBy ?? getCurrentUserEmail(),
        lastModifiedBy: inputPayment.lastModifiedBy ?? getCurrentUserEmail(),
        createdAt: inputPayment.createdAt ?? moment(Date.now()).toString(),
        lastModifiedAt: inputPayment.lastModifiedAt ?? moment(Date.now()).toString(),
    };
    return cloneObject;
}

export const getProjectItemPayPrepopulated = (input) => {
    return {
      name: 'Payment ' + input.name,
      value: input.cost,
      paidAt: moment(Date.now()),
      status: PAYMENT_STATUSES.PAID
    }
}

export const getProjectItemPrepopulatedCategory = (input) => {
    return {
        categoryId: input
    }
}

export const createNewProjectItem = (projectId, userId, contractorId, parentProjectItemId, name = '') => {
    return {
        name: name,
        cost: 0,
        description: null,
        projectId: projectId,
        parentProjectItemId: parentProjectItemId,
        contractorId: contractorId,
        accepted: true,
        completed: true,
        createdBy: userId ?? defaultOwnerEmailAddress,
        lastModifiedBy: userId ?? defaultOwnerEmailAddress,
        createdAt: moment(Date.now()).toString(),
        lastModifiedAt: moment(Date.now()).toString(),
    };
};

export const cloneUserCategory = (inputUserCategory) => {
    return {
        name: inputUserCategory.name,
        type: inputUserCategory.type,
        userId: inputUserCategory.userId ?? 'null',
        createdBy: inputUserCategory.userId ?? defaultOwnerEmailAddress,
        lastModifiedBy: inputUserCategory.userId ?? defaultOwnerEmailAddress,
        createdAt: moment(Date.now()).toString(),
        lastModifiedAt: moment(Date.now()).toString(),
    };
}

export const cloneProjectItem = (inputProjectItem) => {
    const cloneObject = {
        name: inputProjectItem.name,
        cost: inputProjectItem.cost,
        costType: inputProjectItem.costType ?? COST_TYPES.MATERIALS,
        description: inputProjectItem.description ?? '',
        projectId: inputProjectItem.projectId,
        accepted: inputProjectItem.accepted ?? false,
        completed: inputProjectItem.completed ?? false,
        categoryId: inputProjectItem.categoryId ?? DEFAULT_CATEGORY,
        parentProjectItemId: inputProjectItem.parentProjectItemId ?? '',
        contractorId: inputProjectItem.contractorId ?? '',
        paymentId: inputProjectItem.paymentId ?? '',
        createdBy: inputProjectItem.createdBy ?? getCurrentUserEmail(),
        lastModifiedBy: inputProjectItem.lastModifiedBy ?? getCurrentUserEmail(),
        createdAt: inputProjectItem.createdAt ?? moment(Date.now()).toString(),
        lastModifiedAt: inputProjectItem.lastModifiedAt ?? moment(Date.now()).toString(),
    };
    if (inputProjectItem.cost) {
        cloneObject.cost = inputProjectItem.cost;
    }
    if (inputProjectItem.maxTotalCost) {
        cloneObject.maxTotalCost = inputProjectItem.maxTotalCost;
    }
    if (inputProjectItem.minTotalCost) {
        cloneObject.minTotalCost = inputProjectItem.minTotalCost;
    }
    if (inputProjectItem.position) {
        cloneObject.position = inputProjectItem.position;
    }
    return cloneObject;
}

export const cloneProject = (inputProject) => {
    const cloneObject = {
        name: inputProject.name,
        address: inputProject.address,
        description: inputProject.description,
        createdBy: inputProject.createdBy,
        lastModifiedBy: inputProject.lastModifiedBy ?? getCurrentUserEmail(),
        createdAt: inputProject.createdAt,
        lastModifiedAt: inputProject.lastModifiedAt ?? moment(Date.now()).toString(),
        ownerId: inputProject.ownerId?.toLowerCase(),
        status: inputProject.status.toLowerCase(),
        projectPurpose: inputProject.projectPurpose ?? '',
        ownerName: inputProject.ownerName ?? ''
    };
    return cloneObject;
}

export const cloneContractor = (inputContractor) => {
    const cloneObject = {
        name: inputContractor.name,
        email: inputContractor.email,
        title: inputContractor.title,
        address: inputContractor.address ?? '',
        phone: inputContractor.phone ?? '',
        comparative: inputContractor.comparative ?? false,
        projectId: inputContractor.projectId,
        files: inputContractor.files ?? [],
        createdBy: inputContractor.createdBy ?? getCurrentUserEmail(),
        lastModifiedBy: inputContractor.lastModifiedBy ?? getCurrentUserEmail(),
        createdAt: inputContractor.createdAt ?? moment(Date.now()).toString(),
        lastModifiedAt: inputContractor.lastModifiedAt ?? moment(Date.now()).toString(),
    };
    return cloneObject;
}

export const createInitialPayments = (projectItem) => {
    return [{name: '1. Down payment', percentage: 0.2}, {name: '2. Halfway payment', percentage: 0.4}, {name: '3. Last payment', percentage: 0.4}]
    .map((item) => {
        return {... createPaymentBasedOnInput(item, projectItem) };
    });
}

const createPaymentBasedOnInput = (input, projectItem) => {
    const payment = createEmptyPayment(projectItem.projectId, getCurrentUserEmail(), null, input.name);
    payment.value = Number((input.percentage * projectItem.cost).toFixed(2));
    return payment;
}

export const getCostCategoryIdForProjectItem = (projectItem, projectItems) => {
    let itemId = projectItem.key;
    let parentItemId = projectItem?.parentProjectItemId;
    while (parentItemId) {
        itemId = parentItemId;
        parentItemId = projectItems[parentItemId].parentProjectItemId;
    }

    return itemId;
};

export const makeTextCapitalized = (input) => {
    return input.substring(0, 1).toUpperCase() + input.substring(1)
}

export const getProjectPaidCostPercentage = (paidAmount, totalAmount) => {
    if (isNaN(paidAmount) || isNaN(totalAmount) || totalAmount === 0) {
        return 0;
    }
    return parseFloat((paidAmount / totalAmount) * 100).toFixed(1);
};

export const getProjectTypeFromProjectTypeName = (projectTypeQueryParam, projectTypes) => {
    return {
        id: '12345',
        name: 'Baaaathroom',
    };
};

export const formatCurrencyValue = (input) => {
    if (isNaN(Number(input))) {
        return 0;
    } else {
        return Number(Number(input).toFixed(2));
    }
}

export const splitArrayIntoMultipleArraysForSagaQuery = (inputTable) => {
    let multipleArrays = [], counter = 0, singleArray = [];
    for (let i =0; i < inputTable.length; i++) {
      singleArray.push(inputTable[i]);
      counter ++;
      if (counter >= FIXED_SAGA_ARRAY_FOR_QUERY || inputTable.length - 1 === i) {
        counter = 0;
        multipleArrays.push(cloneDeep(singleArray));
        singleArray = [];
      }
    }
    return multipleArrays;
}

export const convertObjectCollectionToArrayForTreeTable = (inputObjectCollection) => {
    const resultArray = [];
    inputObjectCollection && Object.keys(inputObjectCollection)
        .map((id) => {
            resultArray.push({ ...inputObjectCollection[id], key: id}  );
    });
    return resultArray;
}
