import projectitemsActions from "./actions";

const initialState = {
  list: {},
  projectitemsError: '',
  projectitemsSuccess: '',
  selectedProjectitem: {},
  actionName: '',
  parentProjectItemId: '',
  projectitemsSyncSuccess: '',
  projectitemsSyncError: '',
  projectitemsAppendSuccess: '',
  projectitemsAppendError: '',
  isLoading: false,
  modalActive: false,
};

export default function projectitemsReducer(state = initialState, action) {
  switch (action.type) {
    case projectitemsActions.LOAD_PROJECTITEMS_OF_PROJECT_START:
      return { 
        ...state, 
        ...action.payload, 
        isLoading: true,
        modalActive: false, 
      };
    case projectitemsActions.LOAD_PROJECTITEMS_OF_PROJECT_SUCCESS:
      return { 
        ...state, 
        ...action.payload,
        isLoading: false
        };
    case projectitemsActions.LOAD_PROJECTITEMS_OF_PROJECT_FAILED:
        return { 
          ...state, 
          ...action.payload,
          isLoading: false
         };
    case projectitemsActions.LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_START:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
        modalActive: false,
      };
    case projectitemsActions.LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case projectitemsActions.LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_FAILED:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case projectitemsActions.CREATE_PROJECTITEM_START:
      return { ...state, ...action.payload, modalActive: !state.modalActive };    
    case projectitemsActions.BATCH_CREATE_PROJECTITEM_START:
      return { ...state, ...action.payload };
    case projectitemsActions.CREATE_PROJECTITEM_SUCCESS:
        return { ...state, ...action.payload, isLoading: false };
    case projectitemsActions.CREATE_PROJECTITEM_FAILED:
        return { ...state, ...action.payload, isLoading: false };
    case projectitemsActions.EDIT_PROJECTITEM_START:
      return { ...state, ...action.payload, modalActive: !state.modalActive };
    case projectitemsActions.EDIT_PROJECTITEM_SUCCESS:
        return { ...state, ...action.payload };
    case projectitemsActions.EDIT_PROJECTITEM_FAILED:
        return { ...state, ...action.payload };
    case projectitemsActions.LOAD_PROJECTITEM_DETAILS_START:
      return { ...state, ...action.payload };
    case projectitemsActions.LOAD_PROJECTITEM_DETAILS_SUCCESS:
      return { ...state, ...action.payload };
    case projectitemsActions.LOAD_PROJECTITEM_DETAILS_FAILED:
      return { ...state, ...action.payload };
    case projectitemsActions.DELETE_PROJECTITEM_START:
      return { ...state, ...action.payload };
    case projectitemsActions.DELETE_PROJECTITEM_SUCCESS:
      return { ...state, ...action.payload };
    case projectitemsActions.DELETE_PROJECTITEM_FAILED:
      return { ...state, ...action.payload };
    case projectitemsActions.REASSIGN_CATEGORY_WITH_ITEMS_START:
      return { ...state, ...action.payload };
    case projectitemsActions.REASSIGN_CATEGORY_WITH_ITEMS_SUCCESS:
      return { ...state, ...action.payload };
    case projectitemsActions.REASSIGN_CATEGORY_WITH_ITEMS_FAILED:
      return { ...state, ...action.payload };
    case projectitemsActions.ACCEPT_PROJECT_ITEMS_START:
      return { ...state, ...action.payload };
    case projectitemsActions.ACCEPT_PROJECT_ITEMS_SUCCESS:
      return { ...state, ...action.payload };
    case projectitemsActions.ACCEPT_PROJECT_ITEMS_FAILED:
      return { ...state, ...action.payload };
    case projectitemsActions.COMPLETE_PROJECT_ITEMS_START:
      return { ...state, ...action.payload };
    case projectitemsActions.COMPLETE_PROJECT_ITEMS_SUCCESS:
      return { ...state, ...action.payload };
    case projectitemsActions.COMPLETE_PROJECT_ITEMS_FAILED:
      return { ...state, ...action.payload };
    case projectitemsActions.APPEND_PARENT_PROJECT_ITEM_START:
      return { ...state, ...action.payload };
    case projectitemsActions.APPEND_PARENT_PROJECT_ITEM_SUCCESS:
        return { ...state, ...action.payload };
    case projectitemsActions.APPEND_PARENT_PROJECT_ITEM_FAILED:
        return { ...state, ...action.payload };
    case projectitemsActions.CLEAR_SELECTED_PROJECTITEM_START:
      return { ...state, ...action.payload };
    case projectitemsActions.CLEAR_SELECTED_PROJECTITEM_SUCCESS:
      return { ...state, ...action.payload };
      case projectitemsActions.CLEAR_SELECTED_PROJECTITEMS_START:
        return { ...state, ...action.payload };
      case projectitemsActions.CLEAR_SELECTED_PROJECTITEMS_SUCCESS:
        return { ...state, ...action.payload };
    case projectitemsActions.SET_ACTION_NAME_START:
      return { ...state, ...action.payload };
    case projectitemsActions.SET_ACTION_NAME_SUCCESS:
      return { ...state, ...action.payload };
    case projectitemsActions.SET_PARENT_PROJECTITEM_ID_START:
      return { ...state, ...action.payload };
    case projectitemsActions.SET_PARENT_PROJECTITEM_ID_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
