const projectPaymentsActions = {
  //Load project payments
  LOAD_PROJECTPAYMENTS_START: "LOAD_PROJECTPAYMENTS_START",
  LOAD_PROJECTPAYMENTS_SUCCESS: "LOAD_PROJECTPAYMENTS_SUCCESS",
  LOAD_PROJECTPAYMENTS_FAILED: "LOAD_PROJECTPAYMENTS_FAILED",

  //Create project payments
  CREATE_PROJECTPAYMENT_START: "CREATE_PROJECTPAYMENT_START",
  BATCH_CREATE_PROJECTPAYMENT_START: 'BATCH_CREATE_PROJECTPAYMENT_START',
  CREATE_PROJECTPAYMENT_SUCCESS: "CREATE_PROJECTPAYMENT_SUCCESS",
  CREATE_PROJECTPAYMENT_FAILED: "CREATE_PROJECTPAYMENT_FAILED",

  //Update project payments
  UPDATE_PROJECTPAYMENT_START: "UPDATE_PROJECTPAYMENT_START",
  UPDATE_PROJECTPAYMENT_SUCCESS: "UPDATE_PROJECTPAYMENT_SUCCESS",
  UPDATE_PROJECTPAYMENT_FAILED: "UPDATE_PROJECTPAYMENT_FAILED",

  //Delete project payments
  DELETE_PROJECTPAYMENT_START: "DELETE_PROJECTPAYMENT_START",
  DELETE_PROJECTPAYMENT_SUCCESS: "DELETE_PROJECTPAYMENT_SUCCESS",
  DELETE_PROJECTPAYMENT_FAILED: "DELETE_PROJECTPAYMENT_FAILED",

  //CLEAR_PROJECTPAYMENTS_FLAGS
  CLEAR_PROJECTPAYMENTS_FLAGS: 'CLEAR_PROJECTPAYMENTS_FLAGS',

  //Modals and other actions
  TOGGLE_PROJECTPAYMENT_MODAL: "TOGGLE_PROJECTPAYMENT_MODAL",

  // Project Payments action cretors
  createLoadProjectPaymentsStartAction: (projectId) => {
    return {
      type: projectPaymentsActions.LOAD_PROJECTPAYMENTS_START,
      payload: projectId,
    };
  },
  createLoadProjectPaymentsSuccessAction: (projectPayments) => {
    return {
      type: projectPaymentsActions.LOAD_PROJECTPAYMENTS_SUCCESS,
      payload: projectPayments,
    };
  },
  createLoadProjectPaymentsFailedAction: (projectPaymentsError) => {
    return {
      type: projectPaymentsActions.LOAD_PROJECTPAYMENTS_FAILED,
      payload: projectPaymentsError,
    };
  },

  createCreateProjectPaymentsStartAction: (projectPayment) => {
    return {
      type: projectPaymentsActions.CREATE_PROJECTPAYMENT_START,
      payload: projectPayment,
    };
  },
  createBatchCreateProjectPaymentsActionStart: (projectId, projectPayments) => {
    return {
      type: projectPaymentsActions.BATCH_CREATE_PROJECTPAYMENT_START,
      payload: { projectId, projectPayments },
    };
  },
  createCreateProjectPaymentSuccessAction: (projectPaymentSuccess) => {
    return {
      type: projectPaymentsActions.CREATE_PROJECTPAYMENT_SUCCESS,
      payload: projectPaymentSuccess,
    };
  },
  createCreateProjectPaymentFailedAction: (projectPaymentError) => {
    return {
      type: projectPaymentsActions.CREATE_PROJECTPAYMENT_FAILED,
      payload: projectPaymentError,
    };
  },
  // update action creators
  createUpdateProjectPaymentsStartAction: (projectPayment) => {
    return {
      type: projectPaymentsActions.UPDATE_PROJECTPAYMENT_START,
      payload: projectPayment,
    };
  },
  createUpdateProjectPaymentSuccessAction: (projectPaymentSuccess) => {
    return {
      type: projectPaymentsActions.UPDATE_PROJECTPAYMENT_SUCCESS,
      payload: projectPaymentSuccess,
    };
  },
  createUpdateProjectPaymentFailedAction: (projectPaymentError) => {
    return {
      type: projectPaymentsActions.UPDATE_PROJECTPAYMENT_FAILED,
      payload: projectPaymentError,
    };
  },
  // delete action creators
  createDeleteProjectPaymentsStartAction: (projectPayment) => {
    return {
      type: projectPaymentsActions.DELETE_PROJECTPAYMENT_START,
      payload: projectPayment,
    };
  },
  createDeleteProjectPaymentSuccessAction: (projectPaymentSuccess) => {
    return {
      type: projectPaymentsActions.DELETE_PROJECTPAYMENT_SUCCESS,
      payload: projectPaymentSuccess,
    };
  },
  createDeleteProjectPaymentFailedAction: (projectPaymentError) => {
    return {
      type: projectPaymentsActions.DELETE_PROJECTPAYMENT_FAILED,
      payload: projectPaymentError,
    };
  },
  clearProjectPaymentsFlags: (payload) => {
    return {
      type: projectPaymentsActions.CLEAR_PROJECTPAYMENTS_FLAGS,
      payload: payload,
    };
  },
  // Modal and other action creators
  toggleModal: (data = null) => ({
    type: projectPaymentsActions.TOGGLE_PROJECTPAYMENT_MODAL,
    payload: { data },
  }),
};

export default projectPaymentsActions;
