import projectsActions from './actions';

const initialState = {
    list: {},
    searchText: '',
    error: '',
    success: '',
    selectedProject: {},
    selectedTab: '',
    actionName: '',
    syncSuccess: '',
    syncError: '',
    isLoadingProjects: false,
};

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case projectsActions.LOAD_PROJECTS_START:
            return { ...state, ...action.payload, isLoadingProjects: true };
        case projectsActions.LOAD_PROJECTS_SUCCESS:
            return { ...state, ...action.payload, isLoadingProjects: false };
        case projectsActions.LOAD_PROJECTS_FAILED:
            return { ...state, ...action.payload, isLoadingProjects: false };
        case projectsActions.CREATE_PROJECT_START:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_FAILED:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_WITH_CALCULATION_START:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_WITH_CALCULATION_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_WITH_CALCULATION_FAILED:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_BY_WIZARD_START:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_BY_WIZARD_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_PROJECT_BY_WIZARD_FAILED:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_CATEGORY_PROJECT_START:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_CATEGORY_PROJECT_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.CREATE_CATEGORY_PROJECT_FAILED:
            return { ...state, ...action.payload };
        case projectsActions.EDIT_PROJECT_START:
            return { ...state, ...action.payload, isLoadingProjects: true };
        case projectsActions.ASSIGN_HO_START:
            return { ...state, ...action.payload, isLoadingProjects: true };
        case projectsActions.EDIT_PROJECT_SUCCESS:
            return { ...state, ...action.payload, isLoadingProjects: false };
        case projectsActions.EDIT_PROJECT_FAILED:
            return { ...state, ...action.payload, isLoadingProjects: false };
        case projectsActions.DELETE_PROJECT_START:
            return { ...state, ...action.payload };
        case projectsActions.DELETE_PROJECT_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.DELETE_PROJECT_FAILED:
            return { ...state, ...action.payload };
        case projectsActions.SYNC_PROJECT_START:
            return { ...state, ...action.payload };
        case projectsActions.SYNC_PROJECT_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.SYNC_PROJECT_FAILED:
            return { ...state, ...action.payload };
        case projectsActions.SET_ACTION_NAME_START:
            return { ...state, ...action.payload };
        case projectsActions.SET_ACTION_NAME_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.CLEAR_SELECTED_PROJECT_START:
            return { ...state, ...action.payload };
        case projectsActions.CLEAR_SELECTED_PROJECT_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.SET_SELECTED_TAB_START:
            return { ...state, ...action.payload };
        case projectsActions.SET_SELECTED_TAB_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.SET_SEARCH_TEXT:
            return { ...state, searchText: action.payload };
        case projectsActions.LOAD_PROJECT_DETAILS_START:
            return { ...state, ...action.payload };
        case projectsActions.LOAD_PROJECT_DETAILS_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.LOAD_PROJECT_DETAILS_FAILED:
            return { ...state, ...action.payload };
        case projectsActions.ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_START:
            return { ...state, ...action.payload };
        case projectsActions.ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_SUCCESS:
            return { ...state, ...action.payload };
        case projectsActions.ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_FAILED:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
