import profilesActions from "./actions";

const initialState = {
  list: {},
  error: '',
  success: '',
  selectedProfile: {}
};

export default function profilesReducer(state = initialState, action) {
  switch (action.type) {
    case profilesActions.LOAD_PROFILES_START:
      return { ...state, ...action.payload };
    case profilesActions.LOAD_PROFILES_SUCCESS:
        return { ...state, ...action.payload };
    case profilesActions.LOAD_PROFILES_FAILED:
        return { ...state, ...action.payload };
    case profilesActions.LOAD_CONTRACTOR_PROFILES_START:
      return { ...state, ...action.payload };
    case profilesActions.LOAD_CONTRACTOR_PROFILES_SUCCESS:
        return { ...state, ...action.payload };
    case profilesActions.LOAD_CONTRACTOR_PROFILES_FAILED:
        return { ...state, ...action.payload };
    case profilesActions.CREATE_PROFILE_START:
      return { ...state, ...action.payload };
    case profilesActions.CREATE_PROFILE_SUCCESS:
        return { ...state, ...action.payload };
    case profilesActions.CREATE_PROFILE_FAILED:
        return { ...state, ...action.payload };
    case profilesActions.EDIT_PROFILE_START:
      return { ...state, ...action.payload };
    case profilesActions.EDIT_PROFILE_SUCCESS:
        return { ...state, ...action.payload };
    case profilesActions.EDIT_PROFILE_FAILED:
        return { ...state, ...action.payload };
    case profilesActions.CLEAR_SELECTED_PROFILE_START:
      return { ...state, ...action.payload };
    case profilesActions.CLEAR_SELECTED_PROFILE_SUCCESS:
      return { ...state, ...action.payload };
    case profilesActions.LOAD_PROFILE_DETAILS_START:
      return { ...state, ...action.payload };
    case profilesActions.LOAD_PROFILE_DETAILS_SUCCESS:
      return { ...state, ...action.payload };
    case profilesActions.LOAD_PROFILE_DETAILS_FAILED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
