import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import projectsSaga from '@iso/redux/projects/saga';
import contractorsSaga from '@iso/redux/contractors/saga';
import userCategoriesSaga from '@iso/redux/userCategories/saga';
import profilesSaga from '@iso/redux/profiles/saga';
import projectitemsSaga from '@iso/redux/projectitems/saga';
import projectPaymentsSaga from '@iso/redux/projectPayments/saga';
import changeOrdersSaga from '@iso/redux/changeOrders/saga';
import projectWizardSaga from './projectWizard/saga';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        projectsSaga(),
        contractorsSaga(),
        userCategoriesSaga(),
        profilesSaga(),
        projectitemsSaga(),
        projectPaymentsSaga(),
        changeOrdersSaga(),
        projectWizardSaga(),
    ]);
}
