const projectitemsActions = {
  //PROJECTITEMS
  LOAD_PROJECTITEMS_OF_PROJECT_START: 'LOAD_PROJECTITEMS_OF_PROJECT_START',
  LOAD_PROJECTITEMS_OF_PROJECT_SUCCESS: 'LOAD_PROJECTITEMS_OF_PROJECT_SUCCESS',
  LOAD_PROJECTITEMS_OF_PROJECT_FAILED: 'LOAD_PROJECTITEMS_OF_PROJECT_FAILED',

  //ESTIMATION
  LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_START: 'LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_START',
  LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_SUCCESS: 'LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_SUCCESS',
  LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_FAILED: 'LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_FAILED',

  //CREATE_PROJECTITEM
  CREATE_PROJECTITEM_START: 'CREATE_PROJECTITEM_START',
  BATCH_CREATE_PROJECTITEM_START: 'BATCH_CREATE_PROJECTITEM_START',
  CREATE_PROJECTITEM_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECTITEM_FAILED: 'CREATE_PROJECT_FAILED',

  //EDIT_PROJECT
  EDIT_PROJECTITEM_START: 'EDIT_PROJECTITEM_START',
  EDIT_PROJECTITEM_SUCCESS: 'EDIT_PROJECTITEM_SUCCESS',
  EDIT_PROJECTITEM_FAILED: 'EDIT_PROJECTITEM_FAILED',

  //REASSIGN_CATEGORY_WITH_ITEMS
  REASSIGN_CATEGORY_WITH_ITEMS_START: 'REASSIGN_CATEGORY_WITH_ITEMS_START',
  REASSIGN_CATEGORY_WITH_ITEMS_SUCCESS: 'REASSIGN_CATEGORY_WITH_ITEMS_SUCCESS',
  REASSIGN_CATEGORY_WITH_ITEMS_FAILED: 'REASSIGN_CATEGORY_WITH_ITEMS_FAILED',

  //ACCEPT_PROJECT_ITEMS
  ACCEPT_PROJECT_ITEMS_START: 'ACCEPT_PROJECT_ITEMS_START',
  ACCEPT_PROJECT_ITEMS_SUCCESS: 'ACCEPT_PROJECT_ITEMS_SUCCESS',
  ACCEPT_PROJECT_ITEMS_FAILED: 'ACCEPT_PROJECT_ITEMS_FAILED',

  //COMPLETE_PROJECT_ITEMS
  COMPLETE_PROJECT_ITEMS_START: 'COMPLETE_PROJECT_ITEMS_START',
  COMPLETE_PROJECT_ITEMS_SUCCESS: 'COMPLETE_PROJECT_ITEMS_SUCCESS',
  COMPLETE_PROJECT_ITEMS_FAILED: 'COMPLETE_PROJECT_ITEMS_FAILED',

  //PROJECTITEM_DETAILS
  LOAD_PROJECTITEM_DETAILS_START: 'LOAD_PROJECTITEM_DETAILS_START',
  LOAD_PROJECTITEM_DETAILS_SUCCESS: 'LOAD_PROJECTITEM_DETAILS_SUCCESS',
  LOAD_PROJECTITEM_DETAILS_FAILED: 'LOAD_PROJECTITEM_DETAILS_FAILED',

  //DELETE_PROJECT_ITEM
  DELETE_PROJECTITEM_START: 'DELETE_PROJECTITEM_START',
  DELETE_PROJECTITEM_SUCCESS: 'DELETE_PROJECTITEM_SUCCESS',
  DELETE_PROJECTITEM_FAILED: 'DELETE_PROJECTITEM_FAILED',

  //CLEAR_PROJECTITEMS_FLAGS
  CLEAR_PROJECTITEMS_FLAGS: 'CLEAR_PROJECTITEMS_FLAGS',

  //CLEAR_SELECTED_PROJECTITEM
  CLEAR_SELECTED_PROJECTITEM_START: 'CLEAR_SELECTED_PROJECTITEM_START',
  CLEAR_SELECTED_PROJECTITEM_SUCCESS: 'CLEAR_SELECTED_PROJECTITEM_SUCCESS',

  //CLEAR_SELECTED_PROJECTITEMS
  CLEAR_SELECTED_PROJECTITEMS_START: 'CLEAR_SELECTED_PROJECTITEMS_START',
  CLEAR_SELECTED_PROJECTITEMS_SUCCESS: 'CLEAR_SELECTED_PROJECTITEMS_SUCCESS',

  //SET_ACTION_NAME
  SET_ACTION_NAME_START: 'SET_ACTION_NAME_START',
  SET_ACTION_NAME_SUCCESS: 'SET_ACTION_NAME_SUCCESS',

  //SET_PARENT_PROJECTITEM_ID
  SET_PARENT_PROJECTITEM_ID_START: 'SET_PARENT_PROJECTITEM_ID_START',
  SET_PARENT_PROJECTITEM_ID_SUCCESS: 'SET_PARENT_PROJECTITEM_ID_SUCCESS',

  //APPEND_PARENT_PROJECT_ITEM
  APPEND_PARENT_PROJECT_ITEM_START: 'APPEND_PARENT_PROJECT_ITEM_START',
  APPEND_PARENT_PROJECT_ITEM_SUCCESS: 'APPEND_PARENT_PROJECT_ITEM_SUCCESS',
  APPEND_PARENT_PROJECT_ITEM_FAILED: 'APPEND_PARENT_PROJECT_ITEM_FAILED',

  //Modals and other actions
  TOGGLE_PROJECTPAYMENT_MODAL: 'TOGGLE_PROJECTPAYMENT_MODAL',


  createLoadEstimationProjectitemsOfProjectActionStart: (projectId) => {
    return {
      type: projectitemsActions.LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_START,
      payload: projectId,
    };
  },
  createLoadEstimationProjectitemsOfProjectActionSuccess: (projectItems) => {
    return {
      type: projectitemsActions.LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_SUCCESS,
      payload: projectItems,
    };
  },
  createLoadEstimationProjectitemsOfProjectActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_FAILED,
      payload: projectitemsError,
    };
  },
  createLoadProjectitemsOfProjectActionStart: (projectId) => {
    return {
      type: projectitemsActions.LOAD_PROJECTITEMS_OF_PROJECT_START,
      payload: projectId,
    };
  },
  createLoadProjectitemsOfProjectActionSuccess: (projectItems) => {
    return {
      type: projectitemsActions.LOAD_PROJECTITEMS_OF_PROJECT_SUCCESS,
      payload: projectItems,
    };
  },
  createLoadProjectitemsOfProjectActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.LOAD_PROJECTITEMS_OF_PROJECT_FAILED,
      payload: projectitemsError,
    };
  },
  createCreateProjectitemActionStart: (projectitem) => {
    return {
      type: projectitemsActions.CREATE_PROJECTITEM_START,
      payload: projectitem,
    };
  },
  createBatchCreateProjectitemActionStart: (projectId, projectItems) => {
    return {
      type: projectitemsActions.BATCH_CREATE_PROJECTITEM_START,
      payload: { projectId, projectItems },
    };
  },
  createCreateProjectitemActionSuccess: (projectitemsSuccess) => {
    return {
      type: projectitemsActions.CREATE_PROJECTITEM_SUCCESS,
      payload: projectitemsSuccess,
    };
  },
  createCreateProjectitemActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.CREATE_PROJECTITEM_FAILED,
      payload: projectitemsError,
    };
  },
  createEditProjectitemActionStart: (projectitem) => {
    return {
      type: projectitemsActions.EDIT_PROJECTITEM_START,
      payload: projectitem,
    };
  },
  createEditProjectitemActionSuccess: (projectitemsSuccess) => {
    return {
      type: projectitemsActions.EDIT_PROJECTITEM_SUCCESS,
      payload: projectitemsSuccess,
    };
  },
  createEditProjectitemActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.EDIT_PROJECTITEM_FAILED,
      payload: projectitemsError,
    };
  },
  createReassignCategoryWithItemsActionStart: (projectitem) => {
    return {
      type: projectitemsActions.REASSIGN_CATEGORY_WITH_ITEMS_START,
      payload: projectitem,
    };
  },
  createReassignCategoryWithItemsActionSuccess: (projectitemsSuccess) => {
    return {
      type: projectitemsActions.REASSIGN_CATEGORY_WITH_ITEMS_SUCCESS,
      payload: projectitemsSuccess,
    };
  },
  createReassignCategoryWithItemsActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.REASSIGN_CATEGORY_WITH_ITEMS_FAILED,
      payload: projectitemsError,
    };
  },
  createAcceptProjectItemsActionStart: (projectitems) => {
    return {
      type: projectitemsActions.ACCEPT_PROJECT_ITEMS_START,
      payload: projectitems,
    };
  },
  createAcceptProjectItemsActionSuccess: (projectitemsSuccess) => {
    return {
      type: projectitemsActions.ACCEPT_PROJECT_ITEMS_SUCCESS,
      payload: projectitemsSuccess,
    };
  },
  createAcceptProjectItemsActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.ACCEPT_PROJECT_ITEMS_FAILED,
      payload: projectitemsError,
    };
  },
  createCompleteProjectItemsActionStart: (projectitems) => {
    return {
      type: projectitemsActions.COMPLETE_PROJECT_ITEMS_START,
      payload: projectitems,
    };
  },
  createCompleteProjectItemsActionSuccess: (projectitemsSuccess) => {
    return {
      type: projectitemsActions.COMPLETE_PROJECT_ITEMS_SUCCESS,
      payload: projectitemsSuccess,
    };
  },
  createCompleteProjectItemsActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.COMPLETE_PROJECT_ITEMS_FAILED,
      payload: projectitemsError,
    };
  },
  createLoadProjectitemDetailsActionStart: (projectitemId) => {
    return {
      type: projectitemsActions.LOAD_PROJECTITEM_DETAILS_START,
      payload: projectitemId,
    };
  },
  createLoadProjectitemDetailsActionSuccess: (projectItems) => {
    return {
      type: projectitemsActions.LOAD_PROJECTITEM_DETAILS_SUCCESS,
      payload: projectItems,
    };
  },
  createLoadProjectitemDetailsActionFailed: () => {
    return {
      type: projectitemsActions.LOAD_PROJECTITEM_DETAILS_FAILED,
    };
  },
  createDeleteProjectitemActionStart: (payload) => {
    return {
      type: projectitemsActions.DELETE_PROJECTITEM_START,
      payload: payload,
    };
  },
  createDeleteProjectitemActionSuccess: (projectitemsSuccess) => {
    return {
      type: projectitemsActions.DELETE_PROJECTITEM_SUCCESS,
      payload: projectitemsSuccess,
    };
  },
  createDeleteProjectitemActionFailed: (projectitemsError) => {
    return {
      type: projectitemsActions.DELETE_PROJECTITEM_FAILED,
      payload: projectitemsError,
    };
  },
  appendParentProjectItemStart: (payload) => {
    return {
      type: projectitemsActions.APPEND_PARENT_PROJECT_ITEM_START,
      payload: payload,
    };
  },
  appendParentProjectItemSuccess: (appendSuccess) => {
    return {
      type: projectitemsActions.APPEND_PARENT_PROJECT_ITEM_SUCCESS,
      payload: appendSuccess,
    };
  },
  appendParentProjectItemFailed: (appendError) => {
    return {
      type: projectitemsActions.APPEND_PARENT_PROJECT_ITEM_FAILED,
      payload: appendError,
    };
  },
  clearSelectedProjectitemStart: () => {
    return {
      type: projectitemsActions.CLEAR_SELECTED_PROJECTITEM_START,
    };
  },
  clearSelectedProjectitemSuccess: (payload) => {
    return {
      type: projectitemsActions.CLEAR_SELECTED_PROJECTITEM_SUCCESS,
      payload: payload,
    };
  },
  clearSelectedProjectitemsStart: () => {
    return {
      type: projectitemsActions.CLEAR_SELECTED_PROJECTITEMS_START,
    };
  },
  clearSelectedProjectitemsSuccess: (payload) => {
    return {
      type: projectitemsActions.CLEAR_SELECTED_PROJECTITEMS_SUCCESS,
      payload: payload,
    };
  },
  createSetActionNameStart: (actionName) => {
    return {
      type: projectitemsActions.SET_ACTION_NAME_START,
      payload: actionName,
    };
  },
  createSetActionNameSuccess: (actionName) => {
    return {
      type: projectitemsActions.SET_ACTION_NAME_SUCCESS,
      payload: actionName,
    };
  },
  createSetParentProjectItemIdStart: (parentProjectItemId) => {
    return {
      type: projectitemsActions.SET_PARENT_PROJECTITEM_ID_START,
      payload: parentProjectItemId,
    };
  },
  createSetParentProjectItemIdSuccess: (parentProjectItemId) => {
    return {
      type: projectitemsActions.SET_PARENT_PROJECTITEM_ID_SUCCESS,
      payload: parentProjectItemId,
    };
  },
  clearProjectitemsFlags: (payload) => {
    return {
      type: projectitemsActions.CLEAR_PROJECTITEMS_FLAGS,
      payload: payload,
    };
  },

  toggleModal: (data = null) => ({
    type: projectitemsActions.TOGGLE_PROJECTPAYMENT_MODAL,
    payload: { data },
  }),
};

export default projectitemsActions;
