import projectPaymentsActions from "./actions";

//TODO: Update initial state
const initialState = {
  isLoadingPayments: false,
  errorMessage: false,
  modalActive: false,
  projectPayments: {},
  projectPaymentsError: "",
  paymentSuccessMessage: "",
  selectedProjectPayment: {},
};

export default function projectPaymentsReducer(state = initialState, action) {
  switch (action.type) {
    // Load project payments
    case projectPaymentsActions.LOAD_PROJECTPAYMENTS_START:
      return {
        ...state,
        ...action.payload,
        isLoadingPayments: true,
        errorMessage: false,
        modalActive: false,
      };
    case projectPaymentsActions.LOAD_PROJECTPAYMENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoadingPayments: false,
        errorMessage: false,
      };
    case projectPaymentsActions.LOAD_PROJECTPAYMENTS_FAILED:
      return {
        ...state,
        isLoadingPayments: false,
        errorMessage: "Problem loading project payments",
      };
    // Edit project payment
    case projectPaymentsActions.UPDATE_PROJECTPAYMENT_START:
      return { ...state, ...action.payload, modalActive: !state.modalActive };
    case projectPaymentsActions.UPDATE_PROJECTPAYMENT_SUCCESS:
      return { ...state, ...action.payload };
    case projectPaymentsActions.UPDATE_PROJECTPAYMENT_FAILED:
      return { ...state, ...action.payload };
    // Create project payment
    case projectPaymentsActions.CREATE_PROJECTPAYMENT_START:
      return {
        ...state,
        ...action.payload,
        modalActive: !state.modalActive,
      };
    case projectPaymentsActions.BATCH_CREATE_PROJECTPAYMENT_START:
      return { ...state, ...action.payload };
    case projectPaymentsActions.CREATE_PROJECTPAYMENT_SUCCESS:
      return { ...state, ...action.payload, isLoadingPayments: false };
    case projectPaymentsActions.CREATE_PROJECTPAYMENT_FAILED:
      return { ...state, ...action.payload, isLoadingPayments: false };
    // Toggle modal
    case projectPaymentsActions.TOGGLE_PROJECTPAYMENT_MODAL:
      return {
        ...state,
        ...action.payload,
        modalActive: !state.modalActive,
        selectedProjectPayment:
          action.payload.data == null
            ? initialState.selectedProjectPayment
            : action.payload.data,
      };
    default:
      return state;
  }
}
