import { all, takeEvery, put, call } from 'redux-saga/effects';
import { rsf, db } from '@iso/lib/firebase/firebase';
import userCategoriesActions from './actions';
import { getCurrentUserEmail } from '@iso/lib/firebase/firebase.authentication.util';
import { cloneUserCategory } from '@iso/lib/remod-helpers/globalHelpers';

function* loadCategoriesOfUser() {
    try {
        let userCategories = {};
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        const userCategoriesQueryReference = db
        .collection("categories")
        .where('userId', 'in', ['null', loggedInUserEmail])
        const userCategoriesSnapshot = yield call(
            rsf.firestore.getCollection,
            userCategoriesQueryReference
        );
        userCategoriesSnapshot.forEach((userCategory) => {
            userCategories = {
                ...userCategories,
                [userCategory.id]: userCategory.data(),
            };
        });
        yield put(
            userCategoriesActions.createLoadUserCategoriesActionSuccess({
                userCategoriesList: userCategories,
            })
        );
    } catch (error) {
        yield put(
            userCategoriesActions.createLoadUserCategoriesActionFailed({
                userCategoriesError: error,
            })
        );
    }
}
function* loadDefaultCategories() {
    try {
        let defaultCategories = {};
        const userCategoriesQueryReference = db
            .collection("categories")
            .where('userId', 'in', ['null'])
        const userCategoriesSnapshot = yield call(
            rsf.firestore.getCollection,
            userCategoriesQueryReference
        );
        userCategoriesSnapshot.forEach((defaultCategory) => {
            defaultCategories = {
                ...defaultCategories,
                [defaultCategory.id]: defaultCategory.data(),
            };
        });
        yield put(
            userCategoriesActions.createLoadUserCategoriesActionSuccess({
                userCategoriesList: defaultCategories,
            })
        );
    } catch (error) {
        yield put(
            userCategoriesActions.createLoadUserCategoriesActionFailed({
                userCategoriesError: error,
            })
        );
    }
}

function* createUserCategorySaga({ payload }) {
    try {
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        payload.createdBy = loggedInUserEmail;
        payload.lastModifiedBy = loggedInUserEmail;
        const userCategory = cloneUserCategory(payload);
        yield call(rsf.firestore.addDocument, 'categories', userCategory);
        yield put(
            userCategoriesActions.createCreateUserCategoryActionSuccess({
                userCategoriesSuccess: '',
            })
        );
        yield put(userCategoriesActions.createLoadUserCategoriesActionStart());
    } catch (error) {
        yield put(
            userCategoriesActions.createCreateUserCategoryActionFailed({
                userCategoriesError: error,
            })
        );
    }
}

export default function* userCategoriesSaga() {
    yield all([
        takeEvery(userCategoriesActions.LOAD_CATEGORIES_OF_USER_START, loadCategoriesOfUser)
    ]);
    yield all([
        takeEvery(userCategoriesActions.LOAD_DEFAULT_CATEGORIES_START, loadDefaultCategories)
    ]);
    yield all([
        takeEvery(userCategoriesActions.CREATE_CATEGORY_OF_USER_START, createUserCategorySaga)
    ]);
}