export const projectTypes = [
    {
        id: 'whole-house',
        title: 'Whole House',
        description: 'Changes and updates to various aspects of an existing home',
        items: [
            {
                name: 'Furniture and other items or decor for the home',
                categoryId: 'Furnishing',
                costType: 'materials',
                position: 1,
                description:
                    'Movable items and decor that are brought into the home to complete the interior design',
                averageUnitCost: 7.5,
            },
            {
                name: 'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                categoryId: 'Appliances',
                costType: 'materials',
                position: 2,
                description:
                    'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                averageUnitCost: 10,
            },
            {
                name: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                categoryId: 'Tiles and Stones',
                costType: 'materials',
                position: 3,
                description: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                averageUnitCost: 5,
            },
            {
                name: 'Paint, flooring, wall, ceilings and exterior building materials',
                categoryId: 'Building Materials',
                costType: 'materials',
                position: 4,
                description: 'Paint, flooring, wall, ceilings and exterior building materials',
                averageUnitCost: 10,
            },
            {
                name: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                categoryId: 'Furniture',
                costType: 'materials',
                position: 5,
                description: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                averageUnitCost: 15,
            },
            {
                name: 'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                categoryId: 'Plumbing Fixtures',
                costType: 'materials',
                position: 6,
                description:
                    'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                averageUnitCost: 5,
            },
            {
                name: 'Lighting fixtures, Bulbs and other lighting materials',
                categoryId: 'Lighting',
                costType: 'materials',
                position: 7,
                description: 'Lighting fixtures, Bulbs and other lighting materials',
                averageUnitCost: 2.5,
            },
            {
                name: 'Interior and exterior doors, screens, windows and frames',
                categoryId: 'Doors and Windows',
                costType: 'materials',
                position: 8,
                description: 'E.g., Interior and exterior doors, screens, windows and frames',
                averageUnitCost: 10,
            },
            {
                name: 'Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                categoryId: 'Hardware and Accessories',
                costType: 'materials',
                position: 9,
                description:
                    'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                averageUnitCost: 1.5,
            },
            {
                name: 'HVAC units, vents, ductwork, thermostat, ventilation',
                categoryId: 'HVAC equipment',
                costType: 'materials',
                position: 10,
                description: 'HVAC units, vents, ductwork, thermostat, ventilation',
                averageUnitCost: 5,
            },
            {
                name: 'Permits and other legal costs',
                categoryId: 'Permits',
                costType: 'materials',
                position: 11,
                description: 'Permits and other legal costs ',
                averageUnitCost: 2.5,
            },
            {
                name: 'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                categoryId: 'Miscellaneous',
                costType: 'materials',
                position: 12,
                description:
                    'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                averageUnitCost: 1.5,
            },
            {
                name: 'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                categoryId: 'Demolition',
                costType: 'labor',
                position: 13,
                description:
                    'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                averageUnitCost: 2.5,
            },
            {
                name: 'Structural work, building new walls, installing beams, and framing for additions or modifications',
                categoryId: 'Carpentry and Framing',
                costType: 'labor',
                position: 14,
                description:
                    'Structural work, building new walls, installing beams, and framing for additions or modifications',
                averageUnitCost: 7.5,
            },
            {
                name: 'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                categoryId: 'Electrical',
                costType: 'labor',
                position: 15,
                description:
                    'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                averageUnitCost: 5,
            },
            {
                name: 'Installing or reconfiguring plumbing lines, fixtures, and connections',
                categoryId: 'Plumbing',
                costType: 'labor',
                position: 16,
                description:
                    'Installing or reconfiguring plumbing lines, fixtures, and connections',
                averageUnitCost: 5,
            },
            {
                name: 'Buying and Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                categoryId: 'Flooring',
                costType: 'labor',
                position: 17,
                description:
                    'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                averageUnitCost: 4,
            },
            {
                name: 'Installing tiles on walls, floors, or backsplashes',
                categoryId: 'Tile Installation',
                costType: 'labor',
                position: 18,
                description: 'Installing tiles on walls, floors, or backsplashes',
                averageUnitCost: 3.5,
            },
            {
                name: 'Painting walls, ceilings, and other surfaces',
                categoryId: 'Painting',
                costType: 'labor',
                position: 19,
                description: 'Painting walls, ceilings, and other surfaces',
                averageUnitCost: 2.5,
            },
            {
                name: 'Installing or modifying heating and cooling systems',
                categoryId: 'Mechanical and HVAC',
                costType: 'labor',
                position: 20,
                description: 'Installing or modifying heating and cooling systems',
                averageUnitCost: 4,
            },
            {
                name: 'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                categoryId: 'Countertop Installation',
                costType: 'labor',
                position: 21,
                description:
                    'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                averageUnitCost: 2.5,
            },
            {
                name: 'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                categoryId: 'Finish and Trim Work',
                costType: 'labor',
                position: 22,
                description:
                    'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                averageUnitCost: 2.5,
            },
            {
                name: 'Repairing or replacing the roof',
                categoryId: 'Roofing',
                costType: 'labor',
                position: 23,
                description: 'Repairing or replacing the roof',
                averageUnitCost: 7.5,
            },
            {
                name: 'Installing or replacing exterior siding materials',
                categoryId: 'Siding and façade',
                costType: 'labor',
                position: 24,
                description: 'Installing or replacing exterior siding materials',
                averageUnitCost: 10,
            },
            {
                name: 'Installing or replacing windows and doors',
                categoryId: 'Window and Door Installation',
                costType: 'labor',
                position: 25,
                description: 'Installing or replacing windows and doors',
                averageUnitCost: 5,
            },
            {
                name: 'Adding or upgrading insulation in walls, ceilings, and floors',
                categoryId: 'Thermal, Moisture and Insulation',
                costType: 'labor',
                position: 26,
                description: 'Adding or upgrading insulation in walls, ceilings, and floors',
                averageUnitCost: 2.5,
            },
            {
                name: 'Hanging and finishing drywall or plaster walls and ceilings',
                categoryId: 'Drywall and Plaster',
                costType: 'labor',
                position: 27,
                description: 'Hanging and finishing drywall or plaster walls and ceilings',
                averageUnitCost: 3,
            },
            {
                name: 'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                categoryId: 'Landscape and Outdoor Work',
                costType: 'labor',
                position: 28,
                description:
                    'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                averageUnitCost: 5,
            },
            {
                name: 'Project management, coordination, and overseeing the overall renovation',
                categoryId: 'Fees, Profit and General Conditions',
                costType: 'labor',
                position: 29,
                description:
                    'Project management, coordination, and overseeing the overall renovation',
                averageUnitCost: 10,
            },
            {
                name: 'Architect plans and construction management',
                categoryId: 'Architect',
                costType: 'labor',
                position: 30,
                description: 'Architect plans and construction management',
                averageUnitCost: 5,
            },
            {
                name: 'Home design',
                categoryId: 'Designer',
                costType: 'labor',
                position: 31,
                description: 'Home design',
                averageUnitCost: 3,
            },
        ],
    },

    {
        id: 'kitchen',
        title: 'Kitchen',
        description: 'Revamping and enhancing the kitchen',
        items: [
            {
                name: 'Furniture and other items or decor for the kitchen',
                categoryId: 'Furnishing',
                costType: 'materials',
                position: 1,
                description:
                    'Movable items and decor that are brought into the home to complete the interior design',
                averageUnitCost: 6,
            },
            {
                name: 'Kitchen appliances (Fridge, stove) and other',
                categoryId: 'Appliances',
                costType: 'materials',
                position: 2,
                description:
                    'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                averageUnitCost: 68,
            },
            {
                name: 'Tiles and stones for Kitchen, floors, back-splash, etc',
                categoryId: 'Tiles and Stones',
                costType: 'materials',
                position: 3,
                description: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                averageUnitCost: 4,
            },
            {
                name: 'Paint, flooring, wall, ceilings and exterior building materials',
                categoryId: 'Painting',
                costType: 'materials',
                position: 4,
                description: 'Paint, flooring, wall, ceilings and exterior building materials',
                averageUnitCost: 8,
            },
            {
                name: 'Cabinets and countertops for kitchen',
                categoryId: 'Cabinetry and Countertops',
                costType: 'materials',
                position: 5,
                description: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                averageUnitCost: 60,
            },
            {
                name: 'Sinks, faucets, pipes and other plumbing materials',
                categoryId: 'Plumbing Fixtures',
                costType: 'materials',
                position: 6,
                description:
                    'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                averageUnitCost: 4,
            },
            {
                name: 'Lighting fixtures, Bulbs and other lighting materials',
                categoryId: 'Lighting',
                costType: 'materials',
                position: 7,
                description: 'Lighting fixtures, Bulbs and other lighting materials',
                averageUnitCost: 2,
            },
            {
                name: 'Doors, screens, windows and frames',
                categoryId: 'Doors and Windows',
                costType: 'materials',
                position: 8,
                description: 'E.g., Interior and exterior doors, screens, windows and frames',
                averageUnitCost: 8,
            },
            {
                name: 'Doors and cabinet handles, knobs, drawer slides, hinges, kitchen accessories',
                categoryId: 'Hardware and Accessories',
                costType: 'materials',
                position: 9,
                description:
                    'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                averageUnitCost: 1.2,
            },
            {
                name: 'HVAC units, vents, ductwork, thermostat, ventilation',
                categoryId: 'XXXXX',
                costType: 'materials',
                position: 10,
                description: 'HVAC units, vents, ductwork, thermostat, ventilation ',
                averageUnitCost: 0,
            },
            {
                name: 'Permits and other legal costs',
                categoryId: 'Permits',
                costType: 'materials',
                position: 11,
                description: 'Permits and other legal costs ',
                averageUnitCost: 2,
            },
            {
                name: 'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                categoryId: 'XXXXX',
                costType: 'Miscellaneous',
                position: 12,
                description:
                    'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                averageUnitCost: 1.2,
            },
            {
                name: 'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                categoryId: 'Demolition',
                costType: 'labor',
                position: 13,
                description:
                    'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                averageUnitCost: 2,
            },
            {
                name: 'Structural work, building new walls, installing beams, and framing for additions or modifications',
                categoryId: 'Carpentry and Framing',
                costType: 'labor',
                position: 14,
                description:
                    'Structural work, building new walls, installing beams, and framing for additions or modifications',
                averageUnitCost: 6,
            },
            {
                name: 'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                categoryId: 'Electrical',
                costType: 'labor',
                position: 15,
                description:
                    'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                averageUnitCost: 4,
            },
            {
                name: 'Installing or reconfiguring plumbing lines, fixtures, and connections',
                categoryId: 'Plumbing',
                costType: 'labor',
                position: 16,
                description:
                    'Installing or reconfiguring plumbing lines, fixtures, and connections',
                averageUnitCost: 4,
            },
            {
                name: 'Buying and installing new flooring materials, such as tile, hardwood or laminate',
                categoryId: 'Flooring',
                costType: 'labor',
                position: 17,
                description:
                    'Buying and installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                averageUnitCost: 3.2,
            },
            {
                name: 'Installing tiles on walls, floors, or backsplashes',
                categoryId: 'Tile Installation',
                costType: 'labor',
                position: 18,
                description: 'Installing tiles on walls, floors, or backsplashes',
                averageUnitCost: 2.8,
            },
            {
                name: 'Painting walls, ceilings, and other surfaces',
                categoryId: 'Painting',
                costType: 'labor',
                position: 19,
                description: 'Painting walls, ceilings, and other surfaces',
                averageUnitCost: 2,
            },
            {
                name: 'Installing or modifying heating and cooling systems',
                categoryId: 'Mechanical and HVAC',
                costType: 'labor',
                position: 20,
                description: 'Installing or modifying heating and cooling systems',
                averageUnitCost: 0,
            },
            {
                name: 'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                categoryId: 'Countertop Installation',
                costType: 'labor',
                position: 21,
                description:
                    'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                averageUnitCost: 2,
            },
            {
                name: 'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                categoryId: 'Finish and Trim Work',
                costType: 'labor',
                position: 22,
                description:
                    'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                averageUnitCost: 2,
            },
            {
                name: 'Repairing or replacing the roof',
                categoryId: 'Finish and Trim Work',
                costType: 'laborRoofing',
                position: 23,
                description: 'Repairing or replacing the roof',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing exterior siding materials',
                categoryId: 'Siding and façade',
                costType: 'labor',
                position: 24,
                description: 'Installing or replacing exterior siding materials',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing windows and doors',
                categoryId: 'Window and Door Installation',
                costType: 'labor',
                position: 25,
                description: 'Installing or replacing windows and doors',
                averageUnitCost: 4,
            },
            {
                name: 'Adding or upgrading insulation in walls, ceilings, and floors',
                categoryId: 'Thermal, Moisture and Insulation',
                costType: 'labor',
                position: 26,
                description: 'Adding or upgrading insulation in walls, ceilings, and floors',
                averageUnitCost: 2,
            },
            {
                name: 'Hanging and finishing drywall or plaster walls and ceilings',
                categoryId: 'Drywall and Plaster',
                costType: 'labor',
                position: 27,
                description: 'Hanging and finishing drywall or plaster walls and ceilings',
                averageUnitCost: 2.4,
            },
            {
                name: 'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                categoryId: 'Landscape and Outdoor Work',
                costType: 'labor',
                position: 28,
                description:
                    'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                averageUnitCost: 0,
            },
            {
                name: 'Project management, coordination, and overseeing the overall renovation',
                categoryId: 'Fees, Profit and General Conditions',
                costType: 'labor',
                position: 29,
                description:
                    'Project management, coordination, and overseeing the overall renovation',
                averageUnitCost: 8,
            },
            {
                name: 'Architect plans and construction management',
                categoryId: 'Architect',
                costType: 'labor',
                position: 30,
                description: 'Architect plans and construction management',
                averageUnitCost: 4,
            },
            {
                name: 'Kitchen designer',
                categoryId: 'Designer',
                costType: 'labor',
                position: 31,
                description: 'Kitchen design',
                averageUnitCost: 2.4,
            },
        ],
    },

    {
        id: 'bathroom',
        title: 'Bathroom',
        description: 'Enhancing the bathrooms functionality and appearance',
        items: [
            {
                name: 'Furniture and other items or decor for the bathroom',
                categoryId: 'Furnishing',
                costType: 'materials',
                position: 1,
                description:
                    'Movable items and decor that are brought into the home to complete the interior design',
                averageUnitCost: 10,
            },
            {
                name: 'Appliances',
                categoryId: 'Appliances',
                costType: 'materials',
                position: 2,
                description:
                    'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                averageUnitCost: 0,
            },
            {
                name: 'Tiles and stones for bathroom, floors, back-splash, etc',
                categoryId: 'Tiles and Stones',
                costType: 'materials',
                position: 3,
                description: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                averageUnitCost: 20,
            },
            {
                name: 'Paint, flooring, wall, ceilings and exterior building materials',
                categoryId: 'Building materials',
                costType: 'materials',
                position: 4,
                description: 'Paint, flooring, wall, ceilings and exterior building materials',
                averageUnitCost: 20,
            },
            {
                name: 'Cabinets and countertops for bathroom',
                categoryId: 'Cabinetry and Countertops',
                costType: 'materials',
                position: 5,
                description: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                averageUnitCost: 40,
            },
            {
                name: 'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                categoryId: 'Plumbing Fixtures',
                costType: 'materials',
                position: 6,
                description:
                    'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                averageUnitCost: 15,
            },
            {
                name: 'Lighting fixtures, Bulbs and other lighting materials',
                categoryId: 'Lighting',
                costType: 'materials',
                position: 7,
                description: 'Lighting fixtures, Bulbs and other lighting materials',
                averageUnitCost: 7.5,
            },
            {
                name: 'E.g., Interior doors, screens, windows and frames',
                categoryId: 'Doors and Windows',
                costType: 'materials',
                position: 8,
                description: 'E.g., Interior and exterior doors, screens, windows and frames',
                averageUnitCost: 0,
            },
            {
                name: ' Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                categoryId: 'Hardware and Accessories',
                costType: 'materials',
                position: 9,
                description:
                    'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                averageUnitCost: 12,
            },
            {
                name: 'HVAC units, vents, ductwork, thermostat, ventilation',
                categoryId: 'HVAC equipment',
                costType: 'materials',
                position: 10,
                description: 'HVAC units, vents, ductwork, thermostat, ventilation, ',
                averageUnitCost: 0.45,
            },
            {
                name: 'Permits and other legal costs',
                categoryId: 'Permits',
                costType: 'materials',
                position: 11,
                description: 'Permits and other legal costs ',
                averageUnitCost: 9,
            },
            {
                name: 'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                categoryId: 'Miscellaneous',
                costType: 'materials',
                position: 12,
                description:
                    'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                averageUnitCost: 9,
            },
            {
                name: 'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                categoryId: 'Demolition',
                costType: 'labor',
                position: 13,
                description:
                    'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                averageUnitCost: 10,
            },
            {
                name: 'Structural work, building new walls, installing beams, and framing for additions or modifications',
                categoryId: 'Carpentry and Framing',
                costType: 'labor',
                position: 14,
                description:
                    'Structural work, building new walls, installing beams, and framing for additions or modifications',
                averageUnitCost: 15,
            },
            {
                name: 'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                categoryId: 'Electrical',
                costType: 'labor',
                position: 15,
                description:
                    'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                averageUnitCost: 10,
            },
            {
                name: 'Installing or reconfiguring plumbing lines, fixtures, and connections',
                categoryId: 'Plumbing',
                costType: 'labor',
                position: 16,
                description:
                    'Installing or reconfiguring plumbing lines, fixtures, and connections',
                averageUnitCost: 10,
            },
            {
                name: 'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                categoryId: 'Flooring',
                costType: 'labor',
                position: 17,
                description:
                    'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                averageUnitCost: 7.5,
            },
            {
                name: 'Installing tiles on walls, floors, or backsplashes',
                categoryId: 'Tile Installation',
                costType: 'labor',
                position: 18,
                description: 'Installing tiles on walls, floors, or backsplashes',
                averageUnitCost: 6,
            },
            {
                name: 'Painting walls, ceilings, and other surfaces',
                categoryId: 'Painting',
                costType: 'labor',
                position: 19,
                description: 'Painting walls, ceilings, and other surfaces',
                averageUnitCost: 5,
            },
            {
                name: 'Installing or modifying heating and cooling systems',
                categoryId: 'Mechanical and HVAC',
                costType: 'labor',
                position: 20,
                description: 'Installing or modifying heating and cooling systems',
                averageUnitCost: 0.5,
            },
            {
                name: 'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                categoryId: 'Countertop Installation',
                costType: 'labor',
                position: 21,
                description:
                    'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                averageUnitCost: 5,
            },
            {
                name: 'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                categoryId: 'Finish and Trim Work',
                costType: 'labor',
                position: 22,
                description:
                    'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                averageUnitCost: 5,
            },
            {
                name: 'Repairing or replacing the roof',
                categoryId: 'Roofing',
                costType: 'labor',
                position: 23,
                description: 'Repairing or replacing the roof',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing exterior siding materials',
                categoryId: 'Siding and façade',
                costType: 'labor',
                position: 24,
                description: 'Installing or replacing exterior siding materials',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing windows and doors',
                categoryId: 'Window and Door Installation',
                costType: 'labor',
                position: 25,
                description: 'Installing or replacing windows and doors',
                averageUnitCost: 0,
            },
            {
                name: 'Adding or upgrading insulation in walls, ceilings, and floors',
                categoryId: 'Thermal, Moisture and Insulation',
                costType: 'labor',
                position: 26,
                description: 'Adding or upgrading insulation in walls, ceilings, and floors',
                averageUnitCost: 0.5,
            },
            {
                name: 'Hanging and finishing drywall or plaster walls and ceilings',
                categoryId: 'Drywall and Plaster',
                costType: 'labor',
                position: 27,
                description: 'Hanging and finishing drywall or plaster walls and ceilings',
                averageUnitCost: 0,
            },
            {
                name: 'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                categoryId: 'Landscape and Outdoor Work',
                costType: 'labor',
                position: 28,
                description:
                    'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                averageUnitCost: 0,
            },
            {
                name: 'Project management, coordination, and overseeing the overall renovation',
                categoryId: 'Fees, Profit and General Conditions',
                costType: 'labor',
                position: 29,
                description:
                    'Project management, coordination, and overseeing the overall renovation',
                averageUnitCost: 11.175,
            },
            {
                name: 'Architect plans and construction management',
                categoryId: 'Architect',
                costType: 'labor',
                position: 30,
                description: 'Architect plans and construction management',
                averageUnitCost: 4,
            },
            {
                name: 'Bathroom design',
                categoryId: 'Designer',
                costType: 'labor',
                position: 31,
                description: 'Home design',
                averageUnitCost: 3,
            },
        ],
    },

    {
        id: 'basement',
        title: 'Basement Remodel',
        description:
            'Converting a basement into usable living areas or increasing its functionality',
        items: [
            {
                name: 'Movable items and decor that are brought to complete the interior design',
                categoryId: 'Furnishing',
                costType: 'materials',
                position: 1,
                description:
                    'Movable items and decor that are brought to complete the interior design',
                averageUnitCost: 2,
            },
            {
                name: 'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                categoryId: 'Appliances',
                costType: 'materials',
                position: 2,
                description:
                    'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                averageUnitCost: 0,
            },
            {
                name: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                categoryId: 'Tiles and Stones',
                costType: 'materials',
                position: 3,
                description: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                averageUnitCost: 2.5,
            },
            {
                name: 'Paint, flooring, wall, ceilings and exterior building materials',
                categoryId: 'Building Materials',
                costType: 'materials',
                position: 4,
                description: 'Paint, flooring, wall, ceilings and exterior building materials',
                averageUnitCost: 4,
            },
            {
                name: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                categoryId: 'Cabinetry and Countertops',
                costType: 'materials',
                position: 5,
                description: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                averageUnitCost: 6,
            },
            {
                name: 'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                categoryId: 'Plumbing Fixtures',
                costType: 'materials',
                position: 6,
                description:
                    'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                averageUnitCost: 2,
            },
            {
                name: 'Lighting fixtures, Bulbs and other lighting materials',
                categoryId: 'Lighting',
                costType: 'materials',
                position: 7,
                description: 'Lighting fixtures, Bulbs and other lighting materials',
                averageUnitCost: 1.5,
            },
            {
                name: 'E.g., Interior and exterior doors, screens, windows and frames',
                categoryId: 'Doors and Windows',
                costType: 'materials',
                position: 8,
                description: 'E.g., Interior and exterior doors, screens, windows and frames',
                averageUnitCost: 2.5,
            },
            {
                name: 'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                categoryId: 'Hardware and Accessories',
                costType: 'materials',
                position: 9,
                description:
                    'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                averageUnitCost: 1,
            },
            {
                name: 'HVAC units, vents, ductwork, thermostat, ventilation, ',
                categoryId: 'HVAC equipment',
                costType: 'materials',
                position: 10,
                description: 'HVAC units, vents, ductwork, thermostat, ventilation, ',
                averageUnitCost: 4,
            },
            {
                name: 'Permits and other legal costs ',
                categoryId: 'Permits',
                costType: 'materials',
                position: 11,
                description: 'Permits and other legal costs ',
                averageUnitCost: 0.8,
            },
            {
                name: 'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                categoryId: 'Miscellaneous',
                costType: 'materials',
                position: 12,
                description:
                    'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                averageUnitCost: 0.8,
            },
            {
                name: 'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                categoryId: 'Demolition',
                costType: 'labor',
                position: 13,
                description:
                    'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                averageUnitCost: 2.5,
            },
            {
                name: 'Structural work, building new walls, installing beams, and framing for additions or modifications',
                categoryId: 'Carpentry and Framing',
                costType: 'labor',
                position: 14,
                description:
                    'Structural work, building new walls, installing beams, and framing for additions or modifications',
                averageUnitCost: 3,
            },
            {
                name: 'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                categoryId: 'Electrical',
                costType: 'labor',
                position: 15,
                description:
                    'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                averageUnitCost: 2.5,
            },
            {
                name: 'Installing or reconfiguring plumbing lines, fixtures, and connections',
                categoryId: 'Plumbing',
                costType: 'labor',
                position: 16,
                description:
                    'Installing or reconfiguring plumbing lines, fixtures, and connections',
                averageUnitCost: 2.5,
            },
            {
                name: 'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                categoryId: 'Flooring',
                costType: 'labor',
                position: 17,
                description:
                    'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                averageUnitCost: 1.6,
            },
            {
                name: 'Installing tiles on walls, floors, or backsplashes',
                categoryId: 'Tile Installation',
                costType: 'labor',
                position: 18,
                description: 'Installing tiles on walls, floors, or backsplashes',
                averageUnitCost: 1.4,
            },
            {
                name: 'Painting walls, ceilings, and other surfaces',
                categoryId: 'Painting',
                costType: 'labor',
                position: 19,
                description: 'Painting walls, ceilings, and other surfaces',
                averageUnitCost: 1.25,
            },
            {
                name: 'Installing or modifying heating and cooling systems',
                categoryId: 'Mechanical and HVAC',
                costType: 'labor',
                position: 20,
                description: 'Installing or modifying heating and cooling systems',
                averageUnitCost: 2.5,
            },
            {
                name: 'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                categoryId: 'Countertop Installation',
                costType: 'labor',
                position: 21,
                description:
                    'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                averageUnitCost: 1.25,
            },
            {
                name: 'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                categoryId: 'Finish and Trim Work',
                costType: 'labor',
                position: 22,
                description:
                    'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                averageUnitCost: 1.25,
            },
            {
                name: 'Repairing or replacing the roof',
                categoryId: 'Roofing',
                costType: 'labor',
                position: 23,
                description: 'Repairing or replacing the roof',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing exterior siding materials',
                categoryId: 'Siding and façade',
                costType: 'labor',
                position: 24,
                description: 'Installing or replacing exterior siding materials',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing windows and doors',
                categoryId: 'Window and Door Installation',
                costType: 'labor',
                position: 25,
                description: 'Installing or replacing windows and doors',
                averageUnitCost: 0,
            },
            {
                name: 'Adding or upgrading insulation in walls, ceilings, and floors',
                categoryId: 'Thermal, Moisture and Insulation',
                costType: 'labor',
                position: 26,
                description: 'Adding or upgrading insulation in walls, ceilings, and floors',
                averageUnitCost: 0,
            },
            {
                name: 'Hanging and finishing drywall or plaster walls and ceilings',
                categoryId: 'Drywall and Plaster',
                costType: 'labor',
                position: 27,
                description: 'Hanging and finishing drywall or plaster walls and ceilings',
                averageUnitCost: 0,
            },
            {
                name: 'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                categoryId: 'Landscape and Outdoor Work',
                costType: 'labor',
                position: 28,
                description:
                    'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                averageUnitCost: 0,
            },
            {
                name: 'Project management, coordination, and overseeing the overall renovation',
                categoryId: 'Fees, Profit and General Conditions',
                costType: 'labor',
                position: 29,
                description:
                    'Project management, coordination, and overseeing the overall renovation',
                averageUnitCost: 2.5,
            },
            {
                name: 'Architect plans and construction management',
                categoryId: 'Architect',
                costType: 'labor',
                position: 30,
                description: 'Architect plans and construction management',
                averageUnitCost: 1,
            },
            {
                name: 'Home design',
                categoryId: 'Designer',
                costType: 'labor',
                position: 31,
                description: 'Home design',
                averageUnitCost: 0.75,
            },
        ],
    },

    {
        id: 'home-addition',
        title: 'Home Addition',
        description: 'Expansion of a houses existing footprint',
        items: [
            {
                name: 'Movable items and decor that are brought to complete the interior design',
                categoryId: 'Furnishing',
                costType: 'materials',
                position: 1,
                description:
                    'Movable items and decor that are brought to complete the interior design',
                averageUnitCost: 2.5,
            },
            {
                name: 'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                categoryId: 'Appliances',
                costType: 'materials',
                position: 2,
                description:
                    'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                averageUnitCost: 0,
            },
            {
                name: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                categoryId: 'Tiles and Stones',
                costType: 'materials',
                position: 3,
                description: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                averageUnitCost: 2.5,
            },
            {
                name: 'Paint, flooring, wall, ceilings and exterior building materials',
                categoryId: 'Building Materials',
                costType: 'materials',
                position: 4,
                description: 'Paint, flooring, wall, ceilings and exterior building materials',
                averageUnitCost: 5,
            },
            {
                name: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                categoryId: 'Cabinetry and Countertops',
                costType: 'materials',
                position: 5,
                description: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                averageUnitCost: 7.5,
            },
            {
                name: 'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                categoryId: 'Plumbing Fixtures',
                costType: 'materials',
                position: 6,
                description:
                    'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                averageUnitCost: 2.5,
            },
            {
                name: 'Lighting fixtures, Bulbs and other lighting materials',
                categoryId: 'Lighting',
                costType: 'materials',
                position: 7,
                description: 'Lighting fixtures, Bulbs and other lighting materials',
                averageUnitCost: 1.25,
            },
            {
                name: 'E.g., Interior and exterior doors, screens, windows and frames',
                categoryId: 'Doors and Windows',
                costType: 'materials',
                position: 8,
                description: 'E.g., Interior and exterior doors, screens, windows and frames',
                averageUnitCost: 2.5,
            },
            {
                name: 'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                categoryId: 'Hardware and Accessories',
                costType: 'materials',
                position: 9,
                description:
                    'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                averageUnitCost: 2,
            },
            {
                name: 'HVAC units, vents, ductwork, thermostat, ventilation, ',
                categoryId: 'HVAC equipment',
                costType: 'materials',
                position: 10,
                description: 'HVAC units, vents, ductwork, thermostat, ventilation, ',
                averageUnitCost: 5,
            },
            {
                name: 'Permits and other legal costs ',
                categoryId: 'Permits',
                costType: 'materials',
                position: 11,
                description: 'Permits and other legal costs ',
                averageUnitCost: 1.5,
            },
            {
                name: 'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                categoryId: 'Miscellaneous',
                costType: 'materials',
                position: 12,
                description:
                    'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                averageUnitCost: 1.5,
            },
            {
                name: 'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                categoryId: 'Demolition',
                costType: 'labor',
                position: 13,
                description:
                    'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                averageUnitCost: 2.5,
            },
            {
                name: 'Structural work, building new walls, installing beams, and framing for additions or modifications',
                categoryId: 'Carpentry and Framing',
                costType: 'labor',
                position: 14,
                description:
                    'Structural work, building new walls, installing beams, and framing for additions or modifications',
                averageUnitCost: 3.75,
            },
            {
                name: 'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                categoryId: 'Electrical',
                costType: 'labor',
                position: 15,
                description:
                    'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                averageUnitCost: 2.5,
            },
            {
                name: 'Installing or reconfiguring plumbing lines, fixtures, and connections',
                categoryId: 'Plumbing',
                costType: 'labor',
                position: 16,
                description:
                    'Installing or reconfiguring plumbing lines, fixtures, and connections',
                averageUnitCost: 2.5,
            },
            {
                name: 'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                categoryId: 'Flooring',
                costType: 'labor',
                position: 17,
                description:
                    'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                averageUnitCost: 2,
            },
            {
                name: 'Installing tiles on walls, floors, or backsplashes',
                categoryId: 'Tile Installation',
                costType: 'labor',
                position: 18,
                description: 'Installing tiles on walls, floors, or backsplashes',
                averageUnitCost: 1.5,
            },
            {
                name: 'Painting walls, ceilings, and other surfaces',
                categoryId: 'Painting',
                costType: 'labor',
                position: 19,
                description: 'Painting walls, ceilings, and other surfaces',
                averageUnitCost: 1.25,
            },
            {
                name: 'Installing or modifying heating and cooling systems',
                categoryId: 'Mechanical and HVAC',
                costType: 'labor',
                position: 20,
                description: 'Installing or modifying heating and cooling systems',
                averageUnitCost: 2.5,
            },
            {
                name: 'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                categoryId: 'Countertop Installation',
                costType: 'labor',
                position: 21,
                description:
                    'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                averageUnitCost: 1.25,
            },
            {
                name: 'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                categoryId: 'Finish and Trim Work',
                costType: 'labor',
                position: 22,
                description:
                    'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                averageUnitCost: 1.25,
            },
            {
                name: 'Repairing or replacing the roof',
                categoryId: 'Roofing',
                costType: 'labor',
                position: 23,
                description: 'Repairing or replacing the roof',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing exterior siding materials',
                categoryId: 'Siding and façade',
                costType: 'labor',
                position: 24,
                description: 'Installing or replacing exterior siding materials',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing windows and doors',
                categoryId: 'Window and Door Installation',
                costType: 'labor',
                position: 25,
                description: 'Installing or replacing windows and doors',
                averageUnitCost: 0,
            },
            {
                name: 'Adding or upgrading insulation in walls, ceilings, and floors',
                categoryId: 'Thermal, Moisture and Insulation',
                costType: 'labor',
                position: 26,
                description: 'Adding or upgrading insulation in walls, ceilings, and floors',
                averageUnitCost: 0,
            },
            {
                name: 'Hanging and finishing drywall or plaster walls and ceilings',
                categoryId: 'Drywall and Plaster',
                costType: 'labor',
                position: 27,
                description: 'Hanging and finishing drywall or plaster walls and ceilings',
                averageUnitCost: 0,
            },
            {
                name: 'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                categoryId: 'Landscape and Outdoor Work',
                costType: 'labor',
                position: 28,
                description:
                    'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                averageUnitCost: 0,
            },
            {
                name: 'Project management, coordination, and overseeing the overall renovation',
                categoryId: 'Fees, Profit and General Conditions',
                costType: 'labor',
                position: 29,
                description:
                    'Project management, coordination, and overseeing the overall renovation',
                averageUnitCost: 3.75,
            },
            {
                name: 'Architect plans and construction management',
                categoryId: 'Architect',
                costType: 'labor',
                position: 30,
                description: 'Architect plans and construction management',
                averageUnitCost: 2.5,
            },
            {
                name: 'Home design',
                categoryId: 'Designer',
                costType: 'labor',
                position: 31,
                description: 'Home design',
                averageUnitCost: 1.87333333333333,
            },
        ],
    },

    {
        id: 'outdoor-patio',
        title: 'Outdoor / Patio',
        description: 'Revitalizing and upgrading an outdoor space',
        items: [
            {
                name: 'Movable items and decor that are brought to complete the interior design',
                categoryId: 'Furnishing',
                costType: 'materials',
                position: 1,
                description:
                    'Movable items and decor that are brought to complete the interior design',
                averageUnitCost: 2.5,
            },
            {
                name: 'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                categoryId: 'Appliances',
                costType: 'materials',
                position: 2,
                description:
                    'Kitchen (Fridge, stove), Laundry appliances (Washer, dryer) and other',
                averageUnitCost: 0,
            },
            {
                name: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                categoryId: 'Tiles and Stones',
                costType: 'materials',
                position: 3,
                description: 'Tiles and stones for Kitchen, bathrooms, floors, back-splash, etc',
                averageUnitCost: 2.5,
            },
            {
                name: 'Paint, flooring, wall, ceilings and exterior building materials',
                categoryId: 'Building Materials',
                costType: 'materials',
                position: 4,
                description: 'Paint, flooring, wall, ceilings and exterior building materials',
                averageUnitCost: 5,
            },
            {
                name: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                categoryId: 'Cabinetry and Countertops',
                costType: 'materials',
                position: 5,
                description: 'Cabinets and countertops for kitchens and bathrooms and other rooms',
                averageUnitCost: 0,
            },
            {
                name: 'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                categoryId: 'Plumbing Fixtures',
                costType: 'materials',
                position: 6,
                description:
                    'Sinks, bathtubs, showers, toilets, faucets, pipes and other plumbing materials',
                averageUnitCost: 0,
            },
            {
                name: 'Lighting fixtures, Bulbs and other lighting materials',
                categoryId: 'Lighting',
                costType: 'materials',
                position: 7,
                description: 'Lighting fixtures, Bulbs and other lighting materials',
                averageUnitCost: 1.25,
            },
            {
                name: 'E.g., Interior and exterior doors, screens, windows and frames',
                categoryId: 'Doors and Windows',
                costType: 'materials',
                position: 8,
                description: 'E.g., Interior and exterior doors, screens, windows and frames',
                averageUnitCost: 0,
            },
            {
                name: 'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                categoryId: 'Hardware and Accessories',
                costType: 'materials',
                position: 9,
                description:
                    'E.g., Doors and cabinet handles, knobs, drawer slides, hinges, bathroom accessories',
                averageUnitCost: 2,
            },
            {
                name: 'HVAC units, vents, ductwork, thermostat, ventilation, ',
                categoryId: 'HVAC equipment',
                costType: 'materials',
                position: 10,
                description: 'HVAC units, vents, ductwork, thermostat, ventilation, ',
                averageUnitCost: 0,
            },
            {
                name: 'Permits and other legal costs ',
                categoryId: 'Permits',
                costType: 'materials',
                position: 11,
                description: 'Permits and other legal costs ',
                averageUnitCost: 1.5,
            },
            {
                name: 'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                categoryId: 'Miscellaneous',
                costType: 'materials',
                position: 12,
                description:
                    'Other materials (e.g., Adhesives, grout, and caulk, Nails, screws, fasteners, Sealants, Cleaning products)',
                averageUnitCost: 1.5,
            },
            {
                name: 'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                categoryId: 'Demolition',
                costType: 'labor',
                position: 13,
                description:
                    'Removing existing structures, fixtures, and materials that are being replaced or renovated',
                averageUnitCost: 2.5,
            },
            {
                name: 'Structural work, building new walls, installing beams, and framing for additions or modifications',
                categoryId: 'Carpentry and Framing',
                costType: 'labor',
                position: 14,
                description:
                    'Structural work, building new walls, installing beams, and framing for additions or modifications',
                averageUnitCost: 3.75,
            },
            {
                name: 'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                categoryId: 'Electrical',
                costType: 'labor',
                position: 15,
                description:
                    'Rewiring, installing electrical outlets, switches, and lighting fixtures',
                averageUnitCost: 2.5,
            },
            {
                name: 'Installing or reconfiguring plumbing lines, fixtures, and connections',
                categoryId: 'Plumbing',
                costType: 'labor',
                position: 16,
                description:
                    'Installing or reconfiguring plumbing lines, fixtures, and connections',
                averageUnitCost: 0,
            },
            {
                name: 'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                categoryId: 'Flooring',
                costType: 'labor',
                position: 17,
                description:
                    'Installing new flooring materials, such as tile, hardwood, laminate, or carpet',
                averageUnitCost: 2,
            },
            {
                name: 'Installing tiles on walls, floors, or backsplashes',
                categoryId: 'Tile Installation',
                costType: 'labor',
                position: 18,
                description: 'Installing tiles on walls, floors, or backsplashes',
                averageUnitCost: 1.5,
            },
            {
                name: 'Painting walls, ceilings, and other surfaces',
                categoryId: 'Painting',
                costType: 'labor',
                position: 19,
                description: 'Painting walls, ceilings, and other surfaces',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or modifying heating and cooling systems',
                categoryId: 'Mechanical and HVAC',
                costType: 'labor',
                position: 20,
                description: 'Installing or modifying heating and cooling systems',
                averageUnitCost: 0,
            },
            {
                name: 'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                categoryId: 'Countertop Installation',
                costType: 'labor',
                position: 21,
                description:
                    'Measuring and installing countertops, such as granite, quartz, or other surfaces',
                averageUnitCost: 0,
            },
            {
                name: 'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                categoryId: 'Finish and Trim Work',
                costType: 'labor',
                position: 22,
                description:
                    'Adding finishing touches, like installing baseboards, crown molding, and other decorative element',
                averageUnitCost: 1.5,
            },
            {
                name: 'Repairing or replacing the roof',
                categoryId: 'Roofing',
                costType: 'labor',
                position: 23,
                description: 'Repairing or replacing the roof',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing exterior siding materials',
                categoryId: 'Siding and façade',
                costType: 'labor',
                position: 24,
                description: 'Installing or replacing exterior siding materials',
                averageUnitCost: 0,
            },
            {
                name: 'Installing or replacing windows and doors',
                categoryId: 'Window and Door Installation',
                costType: 'labor',
                position: 25,
                description: 'Installing or replacing windows and doors',
                averageUnitCost: 0,
            },
            {
                name: 'Adding or upgrading insulation in walls, ceilings, and floors',
                categoryId: 'Thermal, Moisture and Insulation',
                costType: 'labor',
                position: 26,
                description: 'Adding or upgrading insulation in walls, ceilings, and floors',
                averageUnitCost: 0,
            },
            {
                name: 'Hanging and finishing drywall or plaster walls and ceilings',
                categoryId: 'Drywall and Plaster',
                costType: 'labor',
                position: 27,
                description: 'Hanging and finishing drywall or plaster walls and ceilings',
                averageUnitCost: 0,
            },
            {
                name: 'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                categoryId: 'Landscape and Outdoor Work',
                costType: 'labor',
                position: 28,
                description:
                    'Outdoor renovations, such as landscaping, patio installation, and deck construction',
                averageUnitCost: 0,
            },
            {
                name: 'Project management, coordination, and overseeing the overall renovation',
                categoryId: 'Fees, Profit and General Conditions',
                costType: 'labor',
                position: 29,
                description:
                    'Project management, coordination, and overseeing the overall renovation',
                averageUnitCost: 3.75,
            },
            {
                name: 'Architect plans and construction management',
                categoryId: 'Architect',
                costType: 'labor',
                position: 30,
                description: 'Architect plans and construction management',
                averageUnitCost: 2.5,
            },
            {
                name: 'Home design',
                categoryId: 'Designer',
                costType: 'labor',
                position: 31,
                description: 'Home design',
                averageUnitCost: 1.875,
            },
        ],
    },
];
