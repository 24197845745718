import { all, takeEvery, put } from 'redux-saga/effects';
import projectWizardActions from './actions';
import { projectTypes } from './data/projectTypes';

function* loadProjectTypesSaga({ payload }) {
    try {
        //get that from DB

        yield put(projectWizardActions.createLoadProjectTypesActionSuccess(projectTypes));
    } catch (error) {
        yield put(projectWizardActions.createLoadProjectTypesActionFailed(error));
    }
}

export default function* projectWizardSaga() {
    yield all([takeEvery(projectWizardActions.LOAD_PROJECT_TYPES_START, loadProjectTypesSaga)]);
}
