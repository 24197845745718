import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const RemodelumDashboard = lazy(() => import('./containers/RemodelumDashboard/RemodelumDashboard'));
const EstimationDashboard = lazy(() => import('./containers/RemodelumDashboard/EstimationDashboard'));

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.PROJECT_WIZARD_HO,
        exact: true,
        component: lazy(() =>
            import(
                '@iso/containers/Projects/Wizards/NewWizardHomeOwnerEstimate/ProjectWizardHoEstimate'
            )
        ),
    },
    {
        path: PUBLIC_ROUTE.PAGE_404,
        component: lazy(() => import('@iso/containers/Pages/404/404')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_UP_PARAMS,
        component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_UP,
        component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
    },
    {
        path: PUBLIC_ROUTE.EDIT_PROFILE,
        component: lazy(() => import('@iso/containers/Pages/EditProfile/EditProfile')),
    },
    {
        path: PUBLIC_ROUTE.FORGET_PASSWORD,
        component: lazy(() => import('@iso/containers/Pages/ForgotPassword/ForgotPassword')),
    },
    {
        path: PUBLIC_ROUTE.RESET_PASSWORD,
        component: lazy(() => import('@iso/containers/Pages/ResetPassword/ResetPassword')),
    },
];
function PrivateRoute({ children, ...rest }) {
    const isLoggedIn = useSelector((state) => state.Auth.idToken);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function EstimationRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                children
            }
        />
    );
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            ></Route>
                        ))}
                        <PrivateRoute path='/remodelum'>
                            <RemodelumDashboard />
                        </PrivateRoute>
                        <EstimationRoute path='/estimation'>
                            <EstimationDashboard />
                        </EstimationRoute>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
