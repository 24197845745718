import userCategoriesActions from "./actions";

const initialState = {
  userCategoriesList: {},
  userCategoriesError: '',
  userCategoriesSuccess: '',
  modalActive: false,
  isLoadingCategories: false,
};

export default function contractorsReducer(state = initialState, action) {
  switch (action.type) {
    case userCategoriesActions.LOAD_CATEGORIES_OF_USER_START:
      return { 
        ...state
      };
    case userCategoriesActions.LOAD_CATEGORIES_OF_USER_SUCCESS:
      return { 
        ...state, 
        ...action.payload
        };
    case userCategoriesActions.LOAD_CATEGORIES_OF_USER_FAILED:
        return { 
          ...state, 
          ...action.payload
         };
    case userCategoriesActions.LOAD_DEFAULT_CATEGORIES_START:
      return {
        ...state
      };
    case userCategoriesActions.LOAD_DEFAULT_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case userCategoriesActions.LOAD_DEFAULT_CATEGORIES_FAILED:
      return {
        ...state,
        ...action.payload
      };
    case userCategoriesActions.CREATE_CATEGORY_OF_USER_START:
      return { ...state, ...action.payload, modalActive: !state.modalActive };    
    case userCategoriesActions.CREATE_CATEGORY_OF_USER_SUCCESS:
        return { ...state, ...action.payload, isLoadingContractors: false };
    case userCategoriesActions.CREATE_CATEGORY_OF_USER_FAILED:
        return { ...state, ...action.payload, isLoadingContractors: false };
    default:
      return state;
  }
}