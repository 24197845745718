import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import projectitemsActions from './actions';
import userCategoriesActions from '../userCategories/actions';
import wizardActions from '@iso/redux/projectWizard/actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { createNewProjectItem, cloneProjectItem } from '@iso/lib/remod-helpers/globalHelpers';
import { isLoggedInUserAContractor } from '@iso/lib/remod-helpers/localStorage';
import { getCurrentUserEmail } from '@iso/lib/firebase/firebase.authentication.util';

function* loadEstimationProjectItemsOfProjectSaga({ payload }) {
    let projectItems = {};
    try {
        const collectionReference = db
            .collection('projectitems')
            .where('projectId', '==', payload.projectId)
            .where('contractorId', '==', payload.email)
            .where('accepted', '==', false);
        const projectItemsSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
        projectItemsSnapshot.docs.forEach((project) => {
            projectItems = {
                ...projectItems,
                [project.id]: project.data(),
            };
        });
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionSuccess({
                list: projectItems,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionFailed({
                projectitemsError: error,
            })
        );
    }
}

function* loadProjectitemsOfProjectSaga({ payload }) {
    let projectItems = {};
    let filteredResult = {};
    try {
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        const collectionReference = db
            .collection('projectitems')
            .where('projectId', '==', payload.projectId);
        const projectItemsSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
        projectItemsSnapshot.docs.forEach((project) => {
            projectItems = {
                ...projectItems,
                [project.id]: project.data(),
            };
        });
        if (isLoggedInUserAContractor()) {
            Object.keys(projectItems).map((id, lp) => {
                if ([loggedInUserEmail].includes(projectItems[id].contractorId)) {
                    filteredResult[id] = projectItems[id];
                }
            });
        } else {
            filteredResult = projectItems;
        }
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionSuccess({
                list: filteredResult,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionFailed({
                projectitemsError: error,
            })
        );
    }
}

function* createProjectitemSaga({ payload }) {
    try {
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        payload.createdBy = loggedInUserEmail;
        payload.lastModifiedBy = loggedInUserEmail;
        const projectItem = cloneProjectItem(payload);
        const userCategories = yield select(getUserCategories);
        const userCategoriesArray = Object.keys(userCategories)
            .filter((id) => userCategories[id].type === payload.categoryType)
            .map((id) => userCategories[id].name);
        // if (!userCategoriesArray.includes(projectItem.categoryId)) {
        //     yield put (userCategoriesActions.createCreateUserCategoryActionStart({
        //         name: projectItem.categoryId, type: payload.categoryType, userId: loggedInUserEmail
        //     }));
        // }
        const id = db.collection('projectitems').doc().id;
        yield call(rsf.firestore.setDocument, 'projectitems/' + id, projectItem);
        yield put(
            projectitemsActions.createCreateProjectitemActionSuccess({
                projectitemsSuccess: 'Activity added',
            })
        );
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createCreateProjectitemActionFailed({
                projectitemsError: error,
            })
        );
    }
}

function* appendParentProjectItemSaga({ payload }) {
    try {
        const parentProjectItem = payload.projectItem;
        const parentProjectItemToSave = cloneProjectItem(parentProjectItem);
        parentProjectItemToSave.costType = parentProjectItem.costType;
        const parentProjectItemId = db.collection('projectitems').doc().id;
        yield call(
            rsf.firestore.setDocument,
            'projectitems/' + parentProjectItemId,
            parentProjectItemToSave
        );
        yield all(
            payload.projectItemIds.map((id) =>
                call(appendParentProjectItemId, parentProjectItemId, id)
            )
        );
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: parentProjectItemToSave.projectId,
            })
        );
        yield put(
            projectitemsActions.appendParentProjectItemSuccess({
                projectitemsAppendSuccess: 'Parent projectItem appended successfully',
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.appendParentProjectItemFailed({
                projectitemsAppendError: error,
            })
        );
    }
}

function* batchCreateProjectItemsSaga({ payload }) {
    try {
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        const batch = db.batch();
        const projectItemsCollectionRef = db.collection('projectitems');

        payload.projectItems.forEach((item) => {
            const projectItemRef = projectItemsCollectionRef.doc();
            const newProjectItem = createNewProjectItem(
                payload.projectId,
                loggedInUserEmail,
                loggedInUserEmail,
                ''
            );
            batch.set(projectItemRef, {
                ...newProjectItem,
                name: item.name,
                description: item.description,
                position: item.position,
            });
        });

        yield call([batch, batch.commit]);

        yield put(
            projectitemsActions.createCreateProjectitemActionSuccess({
                projectitemsSuccess: 'Project cost structure created successfully',
            })
        );
        yield put(wizardActions.createSetWizardStepActionStart(3));
    } catch (error) {
        yield put(
            projectitemsActions.createCreateProjectitemActionFailed({
                projectitemsError: `Error creating project cost structure. Error details: ${error}`,
            })
        );
    }
}

const getUserCategories = (state) => {
    return state.userCategories.userCategoriesList;
};

function* reassignCategoryWithItems({ payload }) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    try {
        const userCategories = yield select(getUserCategories);
        const userCategoriesArray = Object.keys(userCategories)
            .filter((id) => userCategories[id].type === payload.categoryType)
            .map((id) => userCategories[id].name);
        if (!userCategoriesArray.includes(payload.name)) {
            yield put(userCategoriesActions.createCreateUserCategoryActionStart({
                name: payload.name, type: payload.categoryType, userId: loggedInUserEmail
            }));
        }
        const projectItemsQueryReference = db
            .collection("projectitems")
            .where('costType', '==', payload.costType)
            .where('projectId', '==', payload.projectId)
            .where("categoryId", "==", payload.previousName);
        const projectItemsSnapshot = yield call(
            rsf.firestore.getCollection,
            projectItemsQueryReference
        );
        const batch = db.batch();
        projectItemsSnapshot.forEach((projectItem) => {
            const projectItemRef = db.collection('projectitems').doc(projectItem.id);
            batch.update(projectItemRef, {
                categoryId: payload.name,
            });
        });
        yield call([batch, batch.commit]);
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createEditProjectitemActionFailed({
                projectitemsError: error,
            })
        );
    }
}

function* completeProjectItems({ payload }) {
    try {
        const projectItemsQueryReference = db
            .collection("projectitems")
            .where('costType', '==', payload.costType)
            .where('projectId', '==', payload.projectId)
            .where("contractorId", "==", payload.contractorId);
        const projectItemsSnapshot = yield call(
            rsf.firestore.getCollection,
            projectItemsQueryReference
        );
        const batch = db.batch();
        projectItemsSnapshot.forEach((projectItem) => {
            const projectItemRef = db.collection('projectitems').doc(projectItem.id);
            batch.update(projectItemRef, {
                completed: true,
            });
        });
        yield call([batch, batch.commit]);
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createEditProjectitemActionFailed({
                projectitemsError: error,
            })
        );
    }
}


function* acceptProjectItems({ payload }) {
    try {
        const projectItemsQueryReference = db
            .collection("projectitems")
            .where('costType', '==', payload.costType)
            .where('projectId', '==', payload.projectId)
            .where("contractorId", "==", payload.contractorId);
        const projectItemsSnapshot = yield call(
            rsf.firestore.getCollection,
            projectItemsQueryReference
        );
        const batch = db.batch();
        if (Array.isArray(payload.projectItemIds)) {
            projectItemsSnapshot.forEach((projectItem) => {
                if (payload.projectItemIds.includes(projectItem.id)) {
                    const projectItemRef = db.collection('projectitems').doc(projectItem.id);
                    batch.update(projectItemRef, {
                        accepted: payload.accepted,
                    });
                }
            });
        } else {
            projectItemsSnapshot.forEach((projectItem) => {
                const projectItemRef = db.collection('projectitems').doc(projectItem.id);
                batch.update(projectItemRef, {
                    accepted: payload.accepted,
                });
            });
        }
        yield call([batch, batch.commit]);
        const linksToRemove = [];
        const linksQueryReference = db
            .collection("links")
            .where('projectId', '==', payload.projectId)
            .where("email", "==", payload.contractorId);
        const linksSnapshot = yield call(
            rsf.firestore.getCollection,
            linksQueryReference
        );
        linksSnapshot.forEach((link) => {
            linksToRemove.push(link.id);
        });
        yield all(linksToRemove.map(id => call(rsf.firestore.deleteDocument, 'links/' + id)));
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createEditProjectitemActionFailed({
                projectitemsError: error,
            })
        );
    }
}

function* editProjectitemSaga({ payload }) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    const projectitemId = payload.key;
    payload.lastModifiedBy = loggedInUserEmail;
    const projectItem = cloneProjectItem(payload);

    try {
        const userCategories = yield select(getUserCategories);
        const userCategoriesArray = Object.keys(userCategories)
            .filter((id) => userCategories[id].type === payload.categoryType)
            .map((id) => userCategories[id].name);
        // if (!userCategoriesArray.includes(projectItem.categoryId)) {
        //     yield put (userCategoriesActions.createCreateUserCategoryActionStart({
        //         name: projectItem.categoryId, type: payload.categoryType, userId: loggedInUserEmail
        //     }));
        // }
        yield call(rsf.firestore.setDocument, 'projectitems/' + projectitemId, projectItem);
        yield put(
            projectitemsActions.createEditProjectitemActionSuccess({
                projectitemsSuccess: 'Activity edited',
            })
        );
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createEditProjectitemActionFailed({
                projectitemsError: error,
            })
        );
    }
}

function* appendParentProjectItemId(parentProjectItemId, projectItemId) {
    const projectItemSnapshot = yield call(
        rsf.firestore.getDocument,
        'projectitems/' + projectItemId
    );
    let projectItemDetails = projectItemSnapshot.data();
    projectItemDetails.parentProjectItemId = parentProjectItemId;
    yield call(rsf.firestore.setDocument, 'projectitems/' + projectItemId, projectItemDetails);
}

function* loadProjectitemDetailsSaga({ payload }) {
    try {
        const projectItemSnapshot = yield call(
            rsf.firestore.getDocument,
            'projectitems/' + payload.projectitemId
        );
        const projectItemDetails = projectItemSnapshot.data();
        projectItemDetails.id = payload.projectitemId;
        projectItemDetails.hasChildren = payload.hasChildren;
        projectItemDetails.baseEstimate = payload.baseEstimate;
        projectItemDetails.optionalCosts = payload.optionalCosts;
        yield put(
            projectitemsActions.createLoadProjectitemDetailsActionSuccess({
                selectedProjectitem: projectItemDetails,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createLoadProjectitemDetailsActionFailed({
                projectitemsError: error,
            })
        );
    }
}

const getSelectedProject = (state) => {
    return state.projects?.selectedProject;
};

function* deleteProjectitemSaga({ payload }) {
    const selectedProject = yield select(getSelectedProject);
    try {
        yield all(payload.projectitemId.map((id) => call(deleteSingleProjectitem, id)));
        yield put(
            projectitemsActions.createDeleteProjectitemActionSuccess({
                projectitemsSuccess: '',
            })
        );
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: selectedProject.id,
            })
        );
    } catch (error) {
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionFailed({
                projectitemsError: error,
            })
        );
    }
}

function* deleteSingleProjectitem(id) {
    yield call(rsf.firestore.deleteDocument, 'projectitems/' + id);
}

function* createSetActionNameSaga({ payload }) {
    yield put(
        projectitemsActions.createSetActionNameSuccess({
            actionName: payload.actionName,
        })
    );
}

function* createSetParentProjectItemIdSaga({ payload }) {
    yield put(
        projectitemsActions.createSetParentProjectItemIdSuccess({
            parentProjectItemId: payload.parentProjectItemId,
        })
    );
}

function* clearSelectedProjectitem() {
    yield put(
        projectitemsActions.clearSelectedProjectitemSuccess({
            selectedProjectitem: {},
        })
    );
}

function* clearSelectedProjectitems() {
    yield put(
        projectitemsActions.clearSelectedProjectitemsSuccess({
            list: {},
        })
    );
}

function* clearProjectitemsFlags() {
    yield put(
        projectitemsActions.createCreateProjectitemActionSuccess({
            projectitemsSyncSuccess: '',
            projectitemsSyncError: '',
            projectitemsSuccess: '',
            projectitemsError: '',
            actionName: '',
        })
    );
}

export default function* projectitemsSaga() {
    yield all([
        takeEvery(
            projectitemsActions.LOAD_PROJECTITEMS_OF_PROJECT_START,
            loadProjectitemsOfProjectSaga
        ),
        takeEvery(
            projectitemsActions.LOAD_ESTIMATION_PROJECTITEMS_OF_PROJECT_START,
            loadEstimationProjectItemsOfProjectSaga
        ),
        takeEvery(projectitemsActions.CREATE_PROJECTITEM_START, createProjectitemSaga),
        takeEvery(projectitemsActions.BATCH_CREATE_PROJECTITEM_START, batchCreateProjectItemsSaga),
        takeEvery(projectitemsActions.DELETE_PROJECTITEM_START, deleteProjectitemSaga),
        takeEvery(projectitemsActions.APPEND_PARENT_PROJECT_ITEM_START, appendParentProjectItemSaga),
        takeEvery(projectitemsActions.CLEAR_PROJECTITEMS_FLAGS, clearProjectitemsFlags),
        takeEvery(projectitemsActions.LOAD_PROJECTITEM_DETAILS_START, loadProjectitemDetailsSaga),
        takeEvery(projectitemsActions.CLEAR_SELECTED_PROJECTITEM_START, clearSelectedProjectitem),
        takeEvery(projectitemsActions.CLEAR_SELECTED_PROJECTITEMS_START, clearSelectedProjectitems),
        takeEvery(projectitemsActions.SET_ACTION_NAME_START, createSetActionNameSaga),
        takeEvery(projectitemsActions.EDIT_PROJECTITEM_START, editProjectitemSaga),
        takeEvery(projectitemsActions.REASSIGN_CATEGORY_WITH_ITEMS_START, reassignCategoryWithItems),
        takeEvery(projectitemsActions.ACCEPT_PROJECT_ITEMS_START, acceptProjectItems),
        takeEvery(projectitemsActions.COMPLETE_PROJECT_ITEMS_START, completeProjectItems),
        takeEvery(
            projectitemsActions.SET_PARENT_PROJECTITEM_ID_START,
            createSetParentProjectItemIdSaga
        ),
    ]);
}
