export const PUBLIC_ROUTE = {
    LANDING: '/',
    PROJECT_WIZARD_HO: '/wizard',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    ESTIMATION: '/estimation',
    SIGN_UP_PARAMS: '/signup/:id',
    FORGET_PASSWORD: '/forgotpassword',
    RESET_PASSWORD: '/resetpassword',
    PAGE_404: '/404',
    PAGE_500: '/500',
    AUTH0_CALLBACK: '/auth0loginCallback',
    EDIT_PROFILE: '/editProfile',
};
