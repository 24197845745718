import projectWizardActions from './actions';


const initialState = {
    step: 0,
};

export default function projectWizardReducer(state = initialState, action) {
    switch (action.type) {
        case projectWizardActions.SET_WIZARD_STEP:
            return { ...state, step: action.payload };

        case projectWizardActions.RESET_WIZARD_STEP:
            return { ...state, step: 0 };

        case projectWizardActions.LOAD_PROJECT_TYPES_START:
            return { ...state, isLoading: true };

        case projectWizardActions.LOAD_PROJECT_TYPES_SUCCESS:
            return { ...state, projectTypes: action.payload, isLoading: false };

        case projectWizardActions.LOAD_PROJECT_TYPES_FAILED:
            return { ...state, projectTypesLoadError: action.payload, isLoading: false };

        case projectWizardActions.SET_SELECTED_PROJECT_TYPE:
            return { ...state, selectedProjectTypeId: action.payload };

        default:
            return state;
    }
}
