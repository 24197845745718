import { all, takeEvery, put, call } from "redux-saga/effects";
import changeOrdersActions from "./actions";
import { rsf, db } from '@iso/lib/firebase/firebase';
import { select } from "redux-saga/effects";
import {
  isLoggedInUserAContractor,
  isLoggedInUserAHomeOwner,
} from "@iso/lib/remod-helpers/localStorage";
import { cloneDeep } from 'lodash';

const getCategories = (state) => {
  return Object.keys(state.projectCostCategories?.costCategories);
};

function* loadContractorChangeOrders(payload) {
  let returnedValue = {};
  const costCategoryIds = yield select(getCategories);
  let multipleArrays = [],
        counter = 0,
        singleArray = [];
    for (let i = 0; i < costCategoryIds.length; i++) {
        singleArray.push(costCategoryIds[i]);
        counter++;
        if (counter >= 10 || costCategoryIds.length - 1 === i) {
            counter = 0;
            multipleArrays.push(cloneDeep(singleArray));
            singleArray = [];
        }
    }
    for (let i = 0; i < multipleArrays.length; i++) {
      const queryReference = db
      .collection("projectitems")
      .where("projectId", "==", payload.projectId)
      .where("changeOrder", "==", true)
      .where("parentProjectItemId", "in", multipleArrays[i]);
  
    const changeOrdersSnapshot = yield call(
      rsf.firestore.getCollection,
      queryReference
    );
    changeOrdersSnapshot.docs.forEach((changeOrder) => {
      returnedValue[changeOrder.id] = changeOrder.data();
  });
  }


  return returnedValue;
}

function* loadHomeOwnerChangeOrders(payload) {
  let returnedValue;

  const queryReference = db
    .collection("projectitems")
    .where("projectId", "==", payload.projectId)
    .where("changeOrder", "==", true);

  const changeOrdersSnapshot = yield call(
    rsf.firestore.getCollection,
    queryReference
  );
  changeOrdersSnapshot.forEach((changeOrder) => {
    returnedValue = {
      ...returnedValue,
      [changeOrder.id]: changeOrder.data(),
    };
  });

  return returnedValue;
}

function* loadChangeOrdersSaga({ payload }) {
  let returnedValue;

  try {
    if (isLoggedInUserAContractor()) {
      returnedValue = yield loadContractorChangeOrders(payload);
    } else if (isLoggedInUserAHomeOwner()) {
      returnedValue = yield loadHomeOwnerChangeOrders(payload);
    } else {
      yield put(
        changeOrdersActions.createLoadChangeOrdersFailedAction({
          changeOrdersError: "Wrong user role",
        })
      );

      return;
    }

    yield put(
      changeOrdersActions.createLoadChangeOrdersSuccessAction({
        changeOrders: returnedValue,
      })
    );
  } catch (error) {
    yield put(
      changeOrdersActions.createLoadChangeOrdersFailedAction({
        changeOrdersError: error,
      })
    );
  }
}

function* createChangeOrderSaga({ payload }) {
  try {
    yield call(rsf.firestore.addDocument, "projectitems", payload);
    yield put(
      changeOrdersActions.createCreateChangeOrderSuccessAction({
        changeOrderSuccessMessage: "Change order created",
      })
    );
    yield put(
      changeOrdersActions.createLoadChangeOrdersStartAction(payload.projectId)
    );
    yield put(
      changeOrdersActions.createLoadChangeOrdersStartAction(payload.projectId)
    );
  } catch (error) {
    yield put(
      changeOrdersActions.createCreateChangeOrderFailedAction({
        changeOrderErrorMessage: error,
      })
    );
  }
}

function* updateChangeOrderSaga({ payload }) {
  const documentId = payload.key;
  delete payload.key;
  
  try {
    yield call(
      rsf.firestore.setDocument,
      "projectitems/" + documentId,
      payload
    );
    yield put(
      changeOrdersActions.createUpdateChangeOrderSuccessAction({
        changeOrderSuccessMessage: "Change order updated",
      })
    );
    yield put(
      changeOrdersActions.createLoadChangeOrdersStartAction(payload.projectId)
    );
  } catch (error) {
    yield put(
      changeOrdersActions.createUpdateChangeOrderFailedAction({
        projectitemsError: error,
      })
    );
  }
}

function* deleteChangeOrderSaga({ payload }) {
  const documentId = payload.key;
  delete payload.key;

  try {
    yield call(rsf.firestore.deleteDocument, "projectitems/" + documentId);
    yield put(
      changeOrdersActions.createDeleteChangeOrderSuccessAction({
        changeOrderSuccessMessage: "Change order deleted",
      })
    );
    yield put(
      changeOrdersActions.createLoadChangeOrdersStartAction(payload.projectId)
    );
  } catch (error) {
    yield put(
      changeOrdersActions.createDeleteChangeOrderFailedAction({
        projectitemsError: error,
      })
    );
  }
}

export default function* changeOrdersSaga() {
  yield all([
    takeEvery(
      changeOrdersActions.LOAD_CHANGE_ORDERS_START,
      loadChangeOrdersSaga
    ),
    takeEvery(
      changeOrdersActions.CREATE_CHANGE_ORDER_START,
      createChangeOrderSaga
    ),
    takeEvery(
      changeOrdersActions.UPDATE_CHANGE_ORDER_START,
      updateChangeOrderSaga
    ),
    takeEvery(
      changeOrdersActions.DELETE_CHANGE_ORDER_START,
      deleteChangeOrderSaga
    ),
  ]);
}
