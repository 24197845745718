const projectsActions = {
    //PROJECTS
    LOAD_PROJECTS_START: 'LOAD_PROJECTS_START',
    LOAD_PROJECTS_SUCCESS: 'LOAD_PROJECTS_SUCCESS',
    LOAD_PROJECTS_FAILED: 'LOAD_PROJECTS_FAILED',

    //CREATE_PROJECT
    CREATE_PROJECT_START: 'CREATE_PROJECT_START',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_FAILED: 'CREATE_PROJECT_FAILED',

    //CREATE_PROJECT_WITH_CALCULATION
    CREATE_PROJECT_WITH_CALCULATION_START: 'CREATE_PROJECT_WITH_CALCULATION_START',
    CREATE_PROJECT_WITH_CALCULATION_SUCCESS: 'CREATE_PROJECT_WITH_CALCULATION_SUCCESS',
    CREATE_PROJECT_WITH_CALCULATION_FAILED: 'CREATE_PROJECT_WITH_CALCULATION_FAILED',

    //CREATE_PROJECT_BY_WIZARD
    CREATE_PROJECT_BY_WIZARD_START: 'CREATE_PROJECT_BY_WIZARD_START',
    CREATE_PROJECT_BY_WIZARD_SUCCESS: 'CREATE_PROJECT_BY_WIZARD_SUCCESS',
    CREATE_PROJECT_BY_WIZARD_FAILED: 'CREATE_PROJECT_BY_WIZARD_FAILED',

    //CREATE_CATEGORY_PROJECT
    CREATE_CATEGORY_PROJECT_START: 'CREATE_CATEGORY_PROJECT_START',
    CREATE_CATEGORY_PROJECT_SUCCESS: 'CREATE_CATEGORY_PROJECT_SUCCESS',
    CREATE_CATEGORY_PROJECT_FAILED: 'CREATE_CATEGORY_PROJECT_FAILED',

    //EDIT_PROJECT
    EDIT_PROJECT_START: 'EDIT_PROJECT_START',
    ASSIGN_HO_START: 'ASSIGN_HO_START',
    EDIT_PROJECT_SUCCESS: 'EDIT_PROJECT_SUCCESS',
    EDIT_PROJECT_FAILED: 'EDIT_PROJECT_FAILED',

    //DELETE_PROJECT_ITEM
    DELETE_PROJECT_START: 'DELETE_PROJECT_START',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_FAILED: 'DELETE_PROJECT_FAILED',

    //PROJECT_DETAILS
    LOAD_PROJECT_DETAILS_START: 'LOAD_PROJECT_DETAILS_START',
    LOAD_PROJECT_DETAILS_SUCCESS: 'LOAD_PROJECT_DETAILS_SUCCESS',
    LOAD_PROJECT_DETAILS_FAILED: 'LOAD_PROJECT_DETAILS_FAILED',

    //SYNC_PROJECT
    SYNC_PROJECT_START: 'SYNC_PROJECT_START',
    SYNC_PROJECT_SUCCESS: 'SYNC_PROJECT_SUCCESS',
    SYNC_PROJECT_FAILED: 'SYNC_PROJECT_FAILED',

    //CLEAR_FLAGS
    CLEAR_FLAGS: 'CLEAR_FLAGS',

    //CLEAR_SELECTED_PROJECT
    CLEAR_SELECTED_PROJECT_START: 'CLEAR_SELECTED_PROJECT_START',
    CLEAR_SELECTED_PROJECT_SUCCESS: 'CLEAR_SELECTED_PROJECT_SUCCESS',

    //SET_ACTION_NAME
    SET_ACTION_NAME_START: 'SET_ACTION_NAME_START',
    SET_ACTION_NAME_SUCCESS: 'SET_ACTION_NAME_SUCCESS',

    //SET_SELECTED_TAB
    SET_SELECTED_TAB_START: 'SET_SELECTED_TAB_START',
    SET_SELECTED_TAB_SUCCESS: 'SET_SELECTED_TAB_SUCCESS',

    // SEARCH
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

    // ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER
    ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_START:
        'ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_START',
    ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_SUCCESS:
        'ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_SUCCESS',
    ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_FAILED:
        'ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_FAILED',

    //Share project with homeonwer
    SHARE_PROJECT_WITH_HO_START: 'SHARE_PROJECT_WITH_HO_START',
    SHARE_PROJECT_WITH_HO_SUCCESS: 'SHARE_PROJECT_WITH_HO_SUCCESS',
    SHARE_PROJECT_WITH_HO_FAILED: 'SHARE_PROJECT_WITH_HO_FAILED',

    // Project Actions
    createLoadUserProjectsActionStart: (userId) => {
        return {
            type: projectsActions.LOAD_PROJECTS_START,
            payload: userId,
        };
    },
    createLoadUserProjectsActionSuccess: (projects) => {
        return {
            type: projectsActions.LOAD_PROJECTS_SUCCESS,
            payload: projects,
        };
    },
    createLoadUserProjectsActionFailed: (error) => {
        return {
            type: projectsActions.LOAD_PROJECTS_FAILED,
            payload: error,
        };
    },
    createCreateProjectActionStart: (project) => {
        return {
            type: projectsActions.CREATE_PROJECT_START,
            payload: project,
        };
    },
    createCreateProjectActionSuccess: (payload) => {
        return {
            type: projectsActions.CREATE_PROJECT_SUCCESS,
            payload: payload,
        };
    },
    createCreateProjectActionFailed: (error) => {
        return {
            type: projectsActions.CREATE_PROJECT_FAILED,
            payload: error,
        };
    },
    createCreateProjectByWizardActionStart: (project) => {
        return {
            type: projectsActions.CREATE_PROJECT_BY_WIZARD_START,
            payload: { project },
        };
    },
    createCreateProjectByWizardActionSuccess: (successPayload) => {
        return {
            type: projectsActions.CREATE_PROJECT_BY_WIZARD_SUCCESS,
            payload: successPayload,
        };
    },
    createCreateProjectByWizardActionFailed: (error) => {
        return {
            type: projectsActions.CREATE_PROJECT_BY_WIZARD_FAILED,
            payload: error,
        };
    },
    createCreateCategoryProjectActionStart: (project) => {
        return {
            type: projectsActions.CREATE_CATEGORY_PROJECT_START,
            payload: project,
        };
    },
    createCreateCategoryProjectActionSuccess: (success) => {
        return {
            type: projectsActions.CREATE_CATEGORY_PROJECT_SUCCESS,
            payload: success,
        };
    },
    createCreateCategoryProjectActionFailed: (error) => {
        return {
            type: projectsActions.CREATE_CATEGORY_PROJECT_FAILED,
            payload: error,
        };
    },

    createProjectWithCalculationActionStart: (selectedProjectTemplate, items) => {
        return {
            type: projectsActions.CREATE_PROJECT_WITH_CALCULATION_START,
            payload: { items, selectedProjectTemplate },
        };
    },

    createProjectWithCalculationActionSuccess: (success) => {
        return {
            type: projectsActions.CREATE_PROJECT_WITH_CALCULATION_SUCCESS,
            payload: success,
        };
    },

    createProjectWithCalculationActionFailed: (error) => {
        return {
            type: projectsActions.CREATE_PROJECT_WITH_CALCULATION_FAILED,
            payload: error,
        };
    },

    createEditProjectActionStart: (project) => {
        return {
            type: projectsActions.EDIT_PROJECT_START,
            payload: project,
        };
    },
    createAssignHoActionStart: (project) => {
        return {
            type: projectsActions.ASSIGN_HO_START,
            payload: project,
        };
    },
    createEditProjectActionSuccess: (success) => {
        return {
            type: projectsActions.EDIT_PROJECT_SUCCESS,
            payload: success,
        };
    },
    createEditProjectActionFailed: (error) => {
        return {
            type: projectsActions.EDIT_PROJECT_FAILED,
            payload: error,
        };
    },
    createDeleteProjectActionStart: (payload) => {
        return {
            type: projectsActions.DELETE_PROJECT_START,
            payload: payload,
        };
    },
    createDeleteProjectActionSuccess: (success) => {
        return {
            type: projectsActions.DELETE_PROJECT_SUCCESS,
            payload: success,
        };
    },
    createDeleteProjectActionFailed: (error) => {
        return {
            type: projectsActions.DELETE_PROJECT_FAILED,
            payload: error,
        };
    },
    createSyncProjectStart: (payload) => {
        return {
            type: projectsActions.SYNC_PROJECT_START,
            payload: payload,
        };
    },
    createSyncProjectSuccess: (syncSuccess) => {
        return {
            type: projectsActions.SYNC_PROJECT_SUCCESS,
            payload: syncSuccess,
        };
    },
    createSyncProjectFailed: (syncError) => {
        return {
            type: projectsActions.SYNC_PROJECT_FAILED,
            payload: syncError,
        };
    },
    createLoadProjectDetailsActionStart: (projectId) => {
        return {
            type: projectsActions.LOAD_PROJECT_DETAILS_START,
            payload: projectId,
        };
    },
    createLoadProjectDetailsActionSuccess: (projects) => {
        return {
            type: projectsActions.LOAD_PROJECT_DETAILS_SUCCESS,
            payload: projects,
        };
    },
    createLoadProjectDetailsActionFailed: () => {
        return {
            type: projectsActions.LOAD_PROJECT_DETAILS_FAILED,
        };
    },
    clearFlags: (payload) => {
        return {
            type: projectsActions.CLEAR_FLAGS,
            payload: payload,
        };
    },
    clearSelectedProjectStart: () => {
        return {
            type: projectsActions.CLEAR_SELECTED_PROJECT_START,
        };
    },
    clearSelectedProjectSuccess: (payload) => {
        return {
            type: projectsActions.CLEAR_SELECTED_PROJECT_SUCCESS,
            payload: payload,
        };
    },
    createSetActionNameStart: (actionName) => {
        return {
            type: projectsActions.SET_ACTION_NAME_START,
            payload: actionName,
        };
    },
    createSetActionNameSuccess: (actionName) => {
        return {
            type: projectsActions.SET_ACTION_NAME_SUCCESS,
            payload: actionName,
        };
    },
    createSetSelectedTabStart: (tabName) => {
        return {
            type: projectsActions.SET_SELECTED_TAB_START,
            payload: tabName,
        };
    },
    createSetSelectedTabSuccess: (tabName) => {
        return {
            type: projectsActions.SET_SELECTED_TAB_SUCCESS,
            payload: tabName,
        };
    },
    setSearchText: (payload) => {
        return {
            type: projectsActions.SET_SEARCH_TEXT,
            payload,
        };
    },

    createAssignAnonymousProjectToCurrentUserStart: (projectId, ownerId) => {
        return {
            type: projectsActions.ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_START,
            payload: { projectId, ownerId },
        };
    },
    createAssignAnonymousProjectToCurrentUserSuccess: (success) => {
        return {
            type: projectsActions.ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_SUCCESS,
            payload: success,
        };
    },
    createAssignAnonymousProjectToCurrentUserFailed: (error) => {
        return {
            type: projectsActions.ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_FAILED,
            payload: error,
        };
    },

    createShareProjectWithHoStart: (actionName) => {
        return {
            type: projectsActions.SHARE_PROJECT_WITH_HO_START,
            payload: actionName,
        };
    },
    createShareProjectWithHoSuccess: (success) => {
        return {
            type: projectsActions.SHARE_PROJECT_WITH_HO_SUCCESS,
            payload: success,
        };
    },
    createShareProjectWithHoFailed: (error) => {
        return {
            type: projectsActions.SHARE_PROJECT_WITH_HO_FAILED,
            payload: error,
        };
    },
};

export default projectsActions;
