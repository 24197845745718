const projectWizardActions = {
    SET_WIZARD_STEP: 'SET_WIZARD_STEP',
    RESET_WIZARD_STEP: 'RESET_WIZARD_STEP',
    SET_WIZARD_PROJECT_STATUS: 'SET_WIZARD_PROJECT_STATUS',
    LOAD_PROJECT_TYPES_START: 'LOAD_PROJECT_TYPES_START',
    LOAD_PROJECT_TYPES_FAILED: 'LOAD_PROJECT_TYPES_FAILED',
    LOAD_PROJECT_TYPES_SUCCESS: 'LOAD_PROJECT_TYPES_SUCCESS',
    SET_SELECTED_PROJECT_TYPE: 'SET_SELECTED_PROJECT_TYPE',

    createSetWizardStepActionStart: (step) => {
        return {
            type: projectWizardActions.SET_WIZARD_STEP,
            payload: step,
        };
    },

    createResetWizardStepActionStart: () => {
        return {
            type: projectWizardActions.RESET_WIZARD_STEP
        };
    },

    createSetWizardProjectStatus: (status) => {
        return {
            type: projectWizardActions.SET_WIZARD_PROJECT_STATUS,
            payload: status,
        };
    },

    createLoadProjectTypesActionStart: () => {
        return {
            type: projectWizardActions.LOAD_PROJECT_TYPES_START,
        };
    },

    createLoadProjectTypesActionSuccess: (projectTypes) => {
        return {
            type: projectWizardActions.LOAD_PROJECT_TYPES_SUCCESS,
            payload: projectTypes,
        };
    },

    createLoadProjectTypesActionFailed: (error) => {
        return {
            type: projectWizardActions.LOAD_PROJECT_TYPES_FAILED,
            payload: error,
        };
    },

    createSetSelectedProjectType: (projectTypeId) => {
        return {
            type: projectWizardActions.SET_SELECTED_PROJECT_TYPE,
            payload: projectTypeId,
        };
    },
};

export default projectWizardActions;
