import changeOrdersActions from "./actions";

//TODO: Update initial state
const initialState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  changeOrders: {},
  changeOrdersError: "",
};

export default function changeOrders(state = initialState, action) {
  switch (action.type) {
    // Load change orders
    case changeOrdersActions.LOAD_CHANGE_ORDERS_START:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case changeOrdersActions.LOAD_CHANGE_ORDERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        errorMessage: false,
      };
    case changeOrdersActions.LOAD_CHANGE_ORDERS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: "Problem loading change orders",
      };
    // Edit change order
    case changeOrdersActions.UPDATE_CHANGE_ORDER_START:
      return { ...state, ...action.payload, modalActive: !state.modalActive };
    case changeOrdersActions.UPDATE_CHANGE_ORDER_SUCCESS:
      return { ...state, ...action.payload };
    case changeOrdersActions.UPDATE_CHANGE_ORDER_FAILED:
      return { ...state, ...action.payload };
    // Create change order
    case changeOrdersActions.CREATE_CHANGE_ORDER_START:
      return {
        ...state,
        ...action.payload,
        modalActive: !state.modalActive,
      };
    case changeOrdersActions.CREATE_CHANGE_ORDER_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case changeOrdersActions.CREATE_CHANGE_ORDER_FAILED:
      return { ...state, ...action.payload, isLoading: false };
    // Toggle modal
    case changeOrdersActions.TOGGLE_CHANGE_ORDER_MODAL:
      return {
        ...state,
        ...action.payload,
        modalActive: !state.modalActive,
      };
    default:
      return state;
  }
}
