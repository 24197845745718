import { all, takeEvery, put, call } from 'redux-saga/effects';
import profilesActions from './actions';
import { rsf, db } from '@iso/lib/firebase/firebase';

function* loadProfilesSaga({ payload }) {
    let profiles;
    try {
        const profilesSnapshot =  yield call(rsf.firestore.getCollection, 'profiles');
        profilesSnapshot.docs.forEach(profile => {
          profiles = {
              ...profiles,
              [profile.id]: profile.data()
            }
          });
        let filteredResult = {};
        if (payload?.profileIds !== undefined) {
          Object.keys(profiles).map((id, lp) => {
            if (payload.profileIds.includes(id)) {
              filteredResult[id] = profiles[id];
            }
          }
          )
        } else {
          filteredResult = profiles;
        }
        yield put (profilesActions.createLoadProfilesActionSuccess({ 'list': filteredResult }));
    } catch (error) {
        yield put (profilesActions.createLoadProfilesActionFailed({'error': error }));
    }
}

function* loadContractorProfilesSaga({ payload }) {
  let profiles;
  try {
      const profilesSnapshot =  yield call(rsf.firestore.getCollection, db.collection('profiles').where('contractorId', '==', payload.contractorId));
      profilesSnapshot.docs.forEach(profile => {
        profiles = {
            ...profiles,
            [profile.id]: profile.data()
          }
        });
      yield put (profilesActions.createLoadContractorProfilesActionSuccess({ 'list': profiles }));
  } catch (error) {
      yield put (profilesActions.createLoadContractorProfilesActionFailed({'error': error }));
  }
}

function* createProfileSaga({ payload }) {
  try {
    const imagesToAdd = payload.imagesToAdd;
    delete payload['imagesToAdd'];
    delete payload['imagesToRemove'];
    const result = [];
    yield all(imagesToAdd.map(imageToAdd => call(addImageRequest, imageToAdd, result)));
    payload.photos = result;
    yield call(
      rsf.firestore.addDocument,
      'profiles',
      payload
    );
    yield put (profilesActions.createCreateProfileActionSuccess({ 'success': 'Profile added' }));
  } catch(error) {
    yield put (profilesActions.createCreateProfileActionFailed({'error': error }));
  }
}

function* editProfileSaga({ payload }) {
  try {
    const imagesToAdd = payload.profile.imagesToAdd;
    const imagesToRemove = payload.profile.imagesToRemove;
    const names = payload.profile.imagesToRemove.map(item => item.name);
    delete payload.profile['imagesToAdd'];
    delete payload.profile['imagesToRemove'];
    const profileId = payload.profile.id;
    delete payload.profile['id'];
    const resultAdd = [];
    yield all(imagesToAdd.map(imageToAdd => call(addImageRequest, imageToAdd, resultAdd)));
    yield all(imagesToRemove.map(imateToRemove => call(removeImageRequest, imateToRemove)));
    payload.profile.photos = payload.profile.photos.filter(photo => !names.includes(photo.name));
    resultAdd.forEach(item => payload.profile.photos.push(item)); 
    yield call(
      rsf.firestore.setDocument,
      'profiles/' + profileId,
      payload.profile
    );
    yield put (profilesActions.createEditProfileActionSuccess({ 'success': 'Profile modified' }));
  } catch(error) {
    yield put (profilesActions.createEditProfileActionFailed({'error': error }));
  }
}

function* addImageRequest(file, result) {
  const task = yield call(rsf.storage.uploadFile, "images/" + file.name, file);
  yield task
  const url = yield call(rsf.storage.getDownloadURL, "images/" + file.name);
  const resultUrl = yield call(fetch, url);
  result.push({'url': resultUrl.url, 'name': file.name});
};

function* removeImageRequest(file) {
  const task =  yield call(rsf.storage.deleteFile, "images/" + file.name);
  yield task
};

function* loadProfileDetailsSaga({ payload }) {
  try {
    const profileSnapshot = yield call(
      rsf.firestore.getDocument,
      'profiles/' + payload.profileId
    );
    const profileDetails = profileSnapshot.data();
    profileDetails.id = payload.profileId; 
    yield put(
      profilesActions.createLoadProfileDetailsActionSuccess({'selectedProfile': profileDetails})
    );
  } catch (error) {
    yield put (profilesActions.createLoadProfileDetailsActionFailed({'error': error }));
  }
  
}

function* clearFlags() {
  yield put (profilesActions.createCreateProfileActionSuccess({ 'success': '', 'error': '' }));
}

function* clearSelectedProfile() {
  yield put (profilesActions.clearSelectedProfileSuccess({ 'selectedProfile': {} }));
}

export default function* usersSaga() {
    yield all([
      takeEvery(profilesActions.LOAD_PROFILES_START, loadProfilesSaga),
      takeEvery(profilesActions.LOAD_CONTRACTOR_PROFILES_START, loadContractorProfilesSaga),
      takeEvery(profilesActions.CREATE_PROFILE_START, createProfileSaga),
      takeEvery(profilesActions.EDIT_PROFILE_START, editProfileSaga),
      takeEvery(profilesActions.CLEAR_FLAGS, clearFlags),
      takeEvery(profilesActions.CLEAR_SELECTED_PROFILE_START, clearSelectedProfile),
      takeEvery(profilesActions.LOAD_PROFILE_DETAILS_START, loadProfileDetailsSaga)
    ]);
  }