const userCategoriesActions = {

    //CATEGORIES_OF_USER
    LOAD_CATEGORIES_OF_USER_START: "LOAD_CATEGORIES_OF_USER_START",
    LOAD_CATEGORIES_OF_USER_SUCCESS: "LOAD_CATEGORIES_OF_USER_SUCCESS",
    LOAD_CATEGORIES_OF_USER_FAILED: "LOAD_CATEGORIES_OF_USER_FAILED",

  //DEFAULT_CATEGORIES
  LOAD_DEFAULT_CATEGORIES_START: "LOAD_DEFAULT_CATEGORIES_START",
  LOAD_DEFAULT_CATEGORIES_SUCCESS: "LOAD_DEFAULT_CATEGORIES_SUCCESS",
  LOAD_DEFAULT_CATEGORIES_FAILED: "LOAD_DEFAULT_CATEGORIES_FAILED",

    //CREATE_CATEGORY_OF_USER
    CREATE_CATEGORY_OF_USER_START: 'CREATE_CATEGORY_OF_USER_START',
    CREATE_CATEGORY_OF_USER_SUCCESS: 'CREATE_CATEGORY_OF_USER_SUCCESS',
    CREATE_CATEGORY_OF_USER_FAILED: 'CREATE_CATEGORY_OF_USER_FAILED',

  createLoadDefaultCategoriesActionStart: () => {
    return {
      type: userCategoriesActions.LOAD_DEFAULT_CATEGORIES_START,
    };
  },
  createLoadDefaultCategoriesActionSuccess: (userCategories) => {
    return {
      type: userCategoriesActions.LOAD_DEFAULT_CATEGORIES_SUCCESS,
      payload: userCategories,
    };
  },
  createLoadDefaultCategoriesActionFailed: (userCategoriesError) => {
    return {
      type: userCategoriesActions.LOAD_DEFAULT_CATEGORIES_FAILED,
      payload: userCategoriesError,
    };
  },
    createLoadUserCategoriesActionStart: () => {
      return {
        type: userCategoriesActions.LOAD_CATEGORIES_OF_USER_START,
      };
    },
    createLoadUserCategoriesActionSuccess: (userCategories) => {
      return {
        type: userCategoriesActions.LOAD_CATEGORIES_OF_USER_SUCCESS,
        payload: userCategories,
      };
    },
    createLoadUserCategoriesActionFailed: (userCategoriesError) => {
      return {
        type: userCategoriesActions.LOAD_CATEGORIES_OF_USER_FAILED,
        payload: userCategoriesError,
      };
    },
    createCreateUserCategoryActionStart: (userCategory) => {
      return {
        type: userCategoriesActions.CREATE_CATEGORY_OF_USER_START,
        payload: userCategory,
      };
    },
    createCreateUserCategoryActionSuccess: (userCategoriesSuccess) => {
      return {
        type: userCategoriesActions.CREATE_CATEGORY_OF_USER_SUCCESS,
        payload: userCategoriesSuccess,
      };
    },
    createCreateUserCategoryActionFailed: (userCategoriesError) => {
      return {
        type: userCategoriesActions.CREATE_CATEGORY_OF_USER_FAILED,
        payload: userCategoriesError,
      };
    },
  };
  
  export default userCategoriesActions;